import { WhitelabelKey } from "../globals";

export interface BrandingConfig {
  logo: string;
  loginBackgroundImage: string;
  loginBackgroundColor: string;
  siteBackground: string;
  primaryColor: string;
  headerColor: string;
  headerImage: string;
  middleColor: string;
}

export const brandingConfig: Record<WhitelabelKey, BrandingConfig> = {
  nj: {
    logo: "assets/images/NJHC_Logo_Full-Color.png",
    loginBackgroundImage: "assets/images/NJ_Hi-res_White.png",
    loginBackgroundColor: "#054f90",
    siteBackground: "#EEEEEE",
    primaryColor: "#4d834c",
    headerColor: "#054f90",
    middleColor: "#EEEEEE",
    headerImage: "assets/images/NJHC_Logo_White.png",
  },
  /* default "docket" styles */
  default: {
    logo: "assets/images/Docket_Logo_Blue.png",
    loginBackgroundImage: "assets/images/LoginPic@2x.png",
    loginBackgroundColor: "#c2e2ff",
    siteBackground: "#3598db",
    primaryColor: "#3598db",
    headerColor: "#032238",
    middleColor: "#237bb8",
    headerImage: "assets/images/Docket_Logo_White.png",
  },
};
