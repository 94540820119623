import { ImmunizationSearch, SearchStatus } from "../../models/Interfaces";
import React from "react";
import { infoLog } from "../../utils/logger";
import moment from "moment";
import { useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import "./searchCard.css";
import getIzProvider from "../../models/providers/IzProviders";

interface SearchCardProps {
  index: number;
  search: ImmunizationSearch;
  onSelect: Function;
}

export default function SearchCard({ search, index, onSelect }: SearchCardProps) {
  const { t } = useTranslation();
  const searchQueue = useAppSelector((store) => store.immunizations.searchQueue);
  const dateVerified = moment(search.dateVerified).isValid();
  const recordsQueue = useAppSelector((store) => store.immunizations.recordsQueue);

  const izProvider = getIzProvider(search.izProviderKey, t);

  const isVerifiedSearch = () => {
    return (
      search.status.trim() !== SearchStatus.noMatch &&
      search.status.trim() !== SearchStatus.basicMatchNoContacts &&
      search.status.trim() !== SearchStatus.partialMatchNoContacts &&
      dateVerified
    );
  };

  const recordsLoading = recordsQueue.find((record) => record.uid === search.uid);

  const getRecordsWaiting = () => {
    let waiting = isVerifiedSearch(); // && (!patientRecords || (patientRecords && patientRecords.length === 0))
    //c2log(`waiting --- ${waiting} ${patientRecords}`)
    return waiting;
  };

  const recordsWaiting = getRecordsWaiting();

  // const getWaitTime = (queued: any[]) => {
  //   let time = '...'
  //   if (status.trim() === SearchStatus.inQueue || recordsWaiting) {
  //     if (queued && queued.length > 0) {
  //       //c2log(`queued[0].estimated_wait_time --- ${queued[0].estimated_wait_time}`)
  //       time = immunizations.getWaitTimeText(queued[0].estimated_wait_time ?? 60)
  //     }
  //   } else {
  //     time = ''
  //   }
  //   return time
  // }

  const getText = () => {
    const searchStatusToLabel = new Map<string, string>([
      [SearchStatus.inQueue, t("immunizations.search_results_pending")],
      [SearchStatus.match, t("immunizations.search_records_found")],
      [SearchStatus.noMatch, t("immunizations.search_try_again")],
      [SearchStatus.noRecords, t("immunizations.search_try_again")],
      [SearchStatus.multiMatch, t("immunizations.search_results_multi_match")],
      [SearchStatus.partialMatchNoContacts, t("immunizations.search_try_again")],
      [SearchStatus.basicMatchNoContacts, t("immunizations.search_basic_match_no_contacts")],
    ]);

    if (!search.status) {
      return searchStatusToLabel.get(SearchStatus.inQueue);
    }

    const searchStatus = search.status.trim();
    if (searchStatus === SearchStatus.partialMatchAltContacts && dateVerified) {
      // This is effectively a MATCH
      return searchStatusToLabel.get(SearchStatus.match);
    }

    /*if (
      (searchStatus === SearchStatus.partialMatchAltContacts && recordsLoading) ||
      (searchStatus === SearchStatus.match && recordsLoading)
    ) {
      return t("immunizations.search_records_loading");
    }*/

    if (searchStatusToLabel.has(searchStatus)) {
      return searchStatusToLabel.get(searchStatus);
    }

    // Un-verified PARTIAL_MATCH_ALT_CONTACTS
    return t("immunizations.search_search_incomplete");
  };

  const getImage = () => {
    const red = "/assets/images/red_status.png";
    const yellow = "/assets/images/yellow_status.png";
    const green = "/assets/images/green_status.png";
    let s = red;
    if (
      search.status === SearchStatus.noMatch ||
      search.status === SearchStatus.noRecords ||
      search.status === SearchStatus.multiMatch ||
      search.status === SearchStatus.basicMatchNoContacts
    ) {
      s = red;
    } else if (search.status !== SearchStatus.noMatch && dateVerified) {
      s = green;
    } else {
      if (search.status.trim() === SearchStatus.partialMatchNoContacts) {
        s = red;
      } else {
        s = yellow;
      }
    }
    return s;
  };

  const getColor = () => {
    let s = "red";
    if (
      search.status.trim() === SearchStatus.noMatch ||
      search.status.trim() === SearchStatus.noRecords ||
      search.status.trim() === SearchStatus.multiMatch ||
      search.status.trim() === SearchStatus.basicMatchNoContacts
    ) {
      s = "red";
    } else if (search.status.trim() !== SearchStatus.noMatch && dateVerified) {
      s = "green";
    } else if (search.status.trim() === SearchStatus.partialMatchAltContacts) {
      s = "yellow";
    } else if (
      (search.status.trim() !== SearchStatus.match && !dateVerified) ||
      (search.status.trim() === SearchStatus.match && recordsLoading)
    ) {
      s = "purple";
    } else {
      if (search.status.trim() === SearchStatus.partialMatchNoContacts) {
        s = "red";
      } else {
        s = "yellow";
      }
    }
    return s;
  };

  const statusCheck = () => {
    return search.status.trim() != SearchStatus.inQueue && !recordsWaiting;
  };

  const getClassName = () => {
    return search.status.trim() === SearchStatus.inQueue
      ? "box radius-large search-card in-queue"
      : "box radius-large search-card";
  };

  const name = search.childFirstName
    ? `${search.childFirstName} ${search.childLastName}`
    : `${search.firstName} ${search.lastName}`;

  return (
    <div className={`${getClassName()} ${getColor()}`} onClick={() => onSelect()}>
      <div className={`badge ${getColor()} radius-top-left-large radius-bottom-left-large`}></div>
      <div className="columns">
        <div className="column is-8">
          <div className="level-left">
            <div className="media">
              <div className="media-content">
                <div role="paragraph" className="title is-5">
                  {getText() + "  "}
                </div>
              </div>
            </div>
          </div>
          <div className="level-left">
            <div
              role="paragraph"
              className="has-text-dark is-6 mt-2"
              data-testid="search-card-name"
            >
              {name}
            </div>
          </div>
          <div className="level-left">
            <p className="is-7 mt-2">
              <strong>
                {`${izProvider.jurisdictionAbbrev}: ${t("immunizations.search_updated")} ${moment(
                  search.dateUpdated
                ).format("L")}`}
              </strong>
            </p>
          </div>
        </div>
        {/*<div className="column is-right is-vcentered">*/}
        {/*  {search.status !== SearchStatus.inQueue && (*/}
        {/*    <span className="icon is-vcentered is-black">*/}
        {/*      <i className="fa fa-chevron-right fa-2x fa-chevron"></i>*/}
        {/*    </span>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
