import { configureStore, ThunkAction, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import loginReducer, { LoginState } from "./redux/loginSlice";
import immunizationsReducer from "./redux/immunizationsSlice";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import db from "./database";

// Create an instance of your EncryptedStorage
const encryptedStorage = db();

// Custom Redux Persist Adapter using your EncryptedStorage
const encryptedStorageAdapter = {
  async setItem(key: string, value: any) {
    await encryptedStorage.setItem(key, value);
  },

  async getItem(key: string) {
    return await encryptedStorage.getItem(key);
  },

  async removeItem(key: string) {
    await encryptedStorage.removeItem(key);
  },
};

// we want to persist the user
const loginConfig = {
  key: "login",
  storage: encryptedStorageAdapter,
};

// we want to persist the immunizations
const persistImmunizationsConfig = {
  key: "immunizations",
  storage: encryptedStorageAdapter,
};

const reducer = {
  immunizations: persistReducer(persistImmunizationsConfig, immunizationsReducer),
  login: persistReducer(loginConfig, loginReducer),
  cryptoLogin: persistReducer(loginConfig, loginReducer),
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ["persist/PERSIST"],
  },
});

export const store = configureStore({
  reducer,
  middleware: customizedMiddleware,
});

export function getStoreWithState(preloadedState?: RootState) {
  return configureStore({ reducer, preloadedState, middleware: customizedMiddleware });
}

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
