import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ImmunizationSearch, SearchStatus } from "../../models/Interfaces";
import { useNavigate } from "react-router";
import { immunizationsMultiMatchPdfURL, immunizationsNoMatchPdfURL } from "../../utils/data";
import { API_DOMAIN, PROTOCOL } from "../../globals";
import getIzProvider from "../../models/providers/IzProviders";
import { Modal } from "../../components/modals/Modal";
import { DocketAPIError, getAPIClient } from "../../apiClient";
import { errorLog } from "../../utils/logger";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { deleteSearch } from "../../redux/immunizationsSlice";

const jim = "/assets/images/Jim_White_resized.png";

interface SearchResultsState {
  isScenarioB: boolean;
}

export function SearchResults() {
  const existingSearch = useAppSelector((store) => store.immunizations.search);

  const noMatch = existingSearch.status === SearchStatus.noMatch;
  const multiMatch = existingSearch.status === SearchStatus.multiMatch;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [deleteSearchModal, setDeleteSearchModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<string>();
  // TODO: We should not be reading local state to consider if this is scenario B.
  // There should be a helper function that looks at the search and determines if it's scenario b
  // Scenario B is a search that has no `verified_at` time with status PARTIAL_MATCH_ALT_CONTACTS.
  const { isScenarioB } = (state as SearchResultsState) || {};

  const providerKey = existingSearch?.izProviderKey.toLowerCase();
  const izProvider = getIzProvider(providerKey, t);

  const navToSearchList = () => {
    navigate("/home/search");
  };

  const navToHelpInfo = () => {
    const source =
      existingSearch.status === SearchStatus.noMatch ||
      existingSearch.status === SearchStatus.basicMatchNoContacts
        ? { uri: immunizationsNoMatchPdfURL, cache: true }
        : { uri: immunizationsMultiMatchPdfURL, cache: true };
    window.open(source.uri, "_blank", "noreferrer");
  };

  const runDeleteSearch = async () => {
    try {
      await getAPIClient().deleteIzSearch(existingSearch.uid, existingSearch.izProviderKey);
      dispatch(deleteSearch(existingSearch));
      navigate("/home/search");
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorModal(e.message);
      }
    }
  };

  const navToIncorrectContacts = () => {
    const url = `${PROTOCOL}://${API_DOMAIN}/api/immunizations/jurisdiction/${providerKey}/incorrect_contacts`;
    window.open(url, "_blank", "noreferrer");
  };

  function getSearchStatusImage() {
    if (isScenarioB || noMatch || multiMatch) {
      return izProvider.scenarioBImagePath;
    }
    return jim;
  }

  function getSearchStatusTextHeader() {
    if (isScenarioB || noMatch || multiMatch) {
      return t("immunizations.search_results_troubleshooting");
    }

    return t("immunizations.search_queued_header");
  }

  function getSearchStatusTextMessage() {
    if (isScenarioB || noMatch) {
      const text = t("immunizations.scenario_b_message", { context: providerKey }).split("|");
      return text[0] + text[1] + text[2];
    }

    if (multiMatch) {
      return t("immunizations.no_records_multi_match_message", { context: providerKey });
    }
    // TODO: Wtf? Use the built-in i18n formatting... :facepalm:
    const [start, bold, end] = t("immunizations.search_queued_message").split("|");
    return start + " " + bold + " " + end;
  }

  function moreInfoCheck() {
    return multiMatch || existingSearch?.status === SearchStatus.noRecords;
  }

  function getButtonTitle() {
    if (isScenarioB || noMatch || multiMatch) {
      return t("immunizations.scenario_b_button_title", { context: providerKey });
    }
    return t("faq.faqs");
  }

  return (
    <>
      {/*{getHeader()}*/}
      <div className="section">
        {deleteSearchModal && (
          <Modal
            title={t("immunizations.alt_contacts_delete_heading")}
            onCloseModal={() => setDeleteSearchModal(true)}
            hasClose={true}
            actionButtons={
              <>
                <button
                  className="button docket-button secondary pl-4 pr-4"
                  aria-label="cancel"
                  onClick={() => setDeleteSearchModal(false)}
                >
                  {t("generic.cancel")}
                </button>
                <button
                  className="button docket-button pl-4 pr-4"
                  aria-label="ok"
                  onClick={async () => {
                    navToIncorrectContacts();
                    await runDeleteSearch();
                  }}
                >
                  {t("generic.ok")}
                </button>
              </>
            }
          >
            <p>{t("immunizations.alt_contacts_delete_message")}</p>
          </Modal>
        )}
        {showErrorModal && (
          <ErrorModal title={t("generic.error")} onCloseModal={() => setShowErrorModal(undefined)}>
            <p>{showErrorModal}</p>
          </ErrorModal>
        )}
        <div className="columns is-centered">
          <div className="column is-half">
            <h1 className="is-size-3 has-text-dark mb-2">{getSearchStatusTextHeader()}</h1>
            <p className="is-size-5 has-text-dark">{getSearchStatusTextMessage()}</p>
            <div className="columns is-centered is-4">
              <div className="column">
                {moreInfoCheck() && (
                  <button onClick={() => navToHelpInfo()} className="button is-large docket-button">
                    {t("immunizations.no_match_multi_match_no_match_button")}
                  </button>
                )}
                <div className="is-flex mt-4">
                  {(isScenarioB || noMatch || multiMatch) && (
                    <button
                      onClick={() => setDeleteSearchModal(true)}
                      className="button docket-button pl-4 pr-4 mr-4"
                    >
                      {getButtonTitle()}
                    </button>
                  )}
                  <br />
                  {isScenarioB || noMatch || multiMatch ? (
                    <button
                      onClick={() => navToSearchList()}
                      className="button docket-button secondary pl-4 pr-4"
                    >
                      {t("generic.dismiss")}
                    </button>
                  ) : (
                    <button
                      onClick={() => navToSearchList()}
                      className="button docket-button pl-4 pr-4"
                    >
                      {t("generic.continue")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="column is-half">
            <img
              className=""
              style={{ width: 300 }}
              src={getSearchStatusImage()}
              alt="Placeholder image"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchResults;
