import { TFunction, i18n } from "i18next";
import { NewJersey } from "./NewJersey";
import { Alaska } from "./Alaska";
import { Idaho } from "./Idaho";
import { Maine } from "./Maine";
import { Minnesota } from "./Minnesota";
import { Utah } from "./Utah";
import { Wyoming } from "./Wyoming";
import { DefaultIzProvider } from "./DefaultIzProvider";

/**
 * The provider interface gives all the 'important' stuff a provider needs to
 * have filled out to function in the app.
 */
export interface IzProvider {
  /**
   * The full name of the government department that hosts the data, e.g. "New Jersey Department of Health"
   */
  governmentDepartmentName: string;

  /**
   * The title of the disclaimer card, shown inside of a record set.
   */
  recordDisclaimerTitle: string;

  /**
   * The body text for the disclaimer card, shown inside of a record set.
   * This may be an empty string.
   */
  recordDisclaimerText: string;

  /**
   * The body text for the legal disclaimer to use this data shown on the new search page.
   * This may be an empty string.
   */
  searchLegalText: string;

  /**
   * Short-form of the IIS name, e.g. NJIIS
   */
  iisAbbrev: string;

  /**
   * The "full name" of the IIS, such as New Jersey Immunization Information System
   */
  iisName: string;

  /**
   * Typically the 2-letter code of the state, e.g. NJ
   */
  jurisdictionAbbrev: string;

  /**
   * Typically the full name of the state, e.g. New Jersey
   */
  jurisdictionName: string;

  /**
   * The image to show during Scenario B (needs more verification)
   */
  scenarioBImagePath: string;

  /**
   * The status images and settings for this IIS.
   */
  statusImages: IzProviderStatusImageSettings;

  /**
   * Report titles for this provider
   */
  reportTitles: string[];

  /**
   * URL to provide screening test information
   */
  screeningResultLink: string;
}

export interface IzProviderStatusImageSettings {
  /**
   * Traditionally the "DUE_NOW" label is yellow.
   *
   * Should this IZ provider show red labels when the status in "DUE_NOW"?
   * This should also apply to the QR code icons.
   */
  redOnDue: boolean;

  /**
   * True if the "NO RECORD" image should be shown for forecasted shots, false otherwise.
   */
  useNoRecordImage: boolean;

  /**
   * A path to the 'OVERDUE' image used to label immunization series.
   */
  overdueImagePath: string;

  /**
   * A path to the 'DUE NOW' (or DUE) image used to label immunization series.
   */
  dueNowImagePath: string;

  /**
   * A path to the 'CURRENT' image used to label immunization series.
   */
  currentImagePath: string;

  /**
   * A path to the 'COMPLETE' image used to label immunization series.
   */
  completeImagePath: string;

  /**
   * A path to the 'IMMUNE' image used to label immunization series.
   */
  immuneImagePath: string;

  /**
   * A path to the 'RECORD' image used to label immunization series.
   */
  recordImagePath: string;

  /**
   * A path to the 'NO RECORD' image used to label immunization series.
   */
  noRecordImagePath: string;

  /**
   * A path to the 'NO RECORD' image used to label immunization series.
   */
  processingImagePath: string;
  /**
   * A path to the 'OVERDUE' image used to label immunization series.
   */
  overdueLabel: string;

  /**
   * A path to the 'DUE NOW' (or DUE) image used to label immunization series.
   */
  dueNowLabel: string;

  /**
   * A path to the 'CURRENT' image used to label immunization series.
   */
  currentLabel: string;

  /**
   * A path to the 'COMPLETE' image used to label immunization series.
   */
  completeLabel: string;

  /**
   * A path to the 'IMMUNE' image used to label immunization series.
   */
  immuneLabel: string;

  /**
   * A path to the 'RECORD' image used to label immunization series.
   */
  recordLabel: string;

  /**
   * A path to the 'NO RECORD' image used to label immunization series.
   */
  noRecordLabel: string;

  /**
   * A path to the 'NO RECORD' image used to label immunization series.
   */
  processingLabel: string;
}

/**
 * Get the appropriate IZ provider class to get all the correct settings.
 *
 * @param izDestinationId The jurisdiction destination ID, e.g. "nj", "ak", etc.
 * @param t The translator function from react-i18next after calling useTranslation()
 * @returns An IzProvider with all required strings for the jurisdiction, or the default provider if a matching provider could not be found.
 */
export function getIzProvider(izDestinationId: string, t: TFunction): IzProvider {
  switch (izDestinationId.toLowerCase()) {
    case "ak":
      return new Alaska(izDestinationId, t);
    case "id":
      return new Idaho(izDestinationId, t);
    case "me":
      return new Maine(izDestinationId, t);
    case "mn":
      return new Minnesota(izDestinationId, t);
    case "nj":
      return new NewJersey(izDestinationId, t);
    case "ut":
      return new Utah(izDestinationId, t);
    case "wy":
      return new Wyoming(izDestinationId, t);
  }
  return new DefaultIzProvider(izDestinationId, t);
}

export default getIzProvider;
