export const STAGE: string = process.env.REACT_APP_STAGE || "beta";

export const API_DOMAIN: string =
  STAGE === "development"
    ? "localhost:3000"
    : STAGE === "alpha"
    ? "alpha.dockethealth.com"
    : STAGE === "beta"
    ? "beta.dockethealth.com"
    : STAGE === "prod"
    ? "dockethealth.com"
    : "";

export const LEGACY_API_DOMAIN: string =
  STAGE === "development"
    ? "localhost:3000"
    : STAGE === "alpha"
    ? "alpha.docket.care"
    : STAGE === "beta"
    ? "beta.docket.care"
    : STAGE === "prod"
    ? "docket.care"
    : "";

export const PROTOCOL: string = STAGE === "development" ? "http" : "https";

export type WhitelabelKey = "nj" | "default";

function getWhitelabelKey(hostname: string): WhitelabelKey {
  if (
    hostname.indexOf("njhealthconnect.com") >= 0 ||
    hostname.indexOf("njhealthconnection.com") >= 0 ||
    hostname.startsWith("nj.")
  ) {
    return "nj";
  }

  return "default";
}

export interface UrlInfo {
  subdomain: string;
  tld: string;
  whitelabelKey: WhitelabelKey;
}

export function parseUrl(location: string): UrlInfo {
  // Parse the URL and extract the subdomain
  const urlDetails = new URL(location);
  // `.host` includes port, `.hostname` does not.
  const splitHost = urlDetails.hostname.split(".");
  // IPv6 starts/ends with [] in a URL
  const isIP =
    urlDetails.hostname.match(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/g) ||
    (urlDetails.hostname.startsWith("[") && urlDetails.hostname.endsWith("]"))
      ? true
      : false;
  const isLocalhost = splitHost[0] === "localhost";
  const whitelabelKey = getWhitelabelKey(urlDetails.hostname);

  if (isIP || isLocalhost) {
    return {
      subdomain: "",
      tld: urlDetails.hostname,
      whitelabelKey: whitelabelKey,
    };
  }

  return {
    // a length of two indicates a TLD, e.g. dockethealth.com
    subdomain: splitHost.length === 2 ? "" : splitHost[0],
    tld: `${splitHost[splitHost.length - 2]}.${splitHost[splitHost.length - 1]}`,
    whitelabelKey: whitelabelKey,
  };
}

const locationInfo = parseUrl(window.location.href);
export const SUBDOMAIN: string = locationInfo.subdomain;
export const TLD: string = locationInfo.tld;
export const WHITELABEL_KEY: WhitelabelKey = locationInfo.whitelabelKey;

export const USE_WHITELABEL_BRANDING = STAGE === "prod" ? false : true;

// conditional for enabling screening dropdown, right now just disabling in prod
export const SCREENINGS_ENABLED = STAGE === "prod" ? false : true;

// No development mode for apple sign in
export const REDIRECT_URI: string =
  STAGE !== "development"
    ? `https://${SUBDOMAIN}.${SUBDOMAIN === "app" ? "" : "app."}${API_DOMAIN}`
    : "http://localhost:3000"; // The library barfs if there's no string

// These should be all of the valid service worker message types
export enum SWMessageTypes {
  Login = "Login",
}

// Number of minutes before logout
export const IDLE_TIMEOUT: number = 5;

export const STAY_LOGGED_IN_PW = "D0ck3t*nTheW3B@@!!";

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export {};
