import {
  Immunization,
  ImmunizationAdministrationLocation,
  ImmunizationEvent,
  ImmunizationRecordAttributes,
  ImmunizationSearchAPI,
  ImmunizationSearchAPIData,
  ImmunizationSearchAttributes,
  PatientAPI,
} from "../apiClient";
import * as interfaces from "../models/Interfaces";
import {
  ImmunizationPatient,
  ImmunizationPatientContact,
  ImmunizationPatientReport,
  ImmunizationRecord,
  ImmunizationSearch,
  SearchStatus,
} from "../models/Interfaces";
import moment from "moment";

export const reports = [
  "school_report",
  "forecast_report",
  "personal_report",
  "history_report", // default
];

const searchMock: ImmunizationSearch = {
  firstName: "Zztestb",
  lastName: "Zzscenariob",
  legalSex: "M",
  dateOfBirth: moment().format("YYYY-MM-DD"),
  childFirstName: "",
  childLastName: "",
  childLegalSex: "",
  childDateOfBirth: "",
  dateUpdated: moment().format("YYYY-MM-DD"),
  dateVerified: moment().format("YYYY-MM-DD"),
  uid: "ef40d2c4-88ef-4c34-8b0f-6c02a1b00a05",
  izProviderId: 2,
  izProviderKey: "MN",
  status: "PARTIAL_MATCH_ALT_CONTACTS",
  whoAmI: "guardian",
  recordsFound: 0,
  patients: [
    {
      firstName: "ZZTESTB",
      lastName: "ZZSCENARIOB",
      dob: moment().format("YYYY-MM-DD"),
      userId: 6,
      searchUid: "ef40d2c4-88ef-4c34-8b0f-6c02a1b00a05",
      izProviderId: 2,
      izProviderKey: "MN",
      patientId: "9038433",
      contacts: [
        { type: "phone", value: "********68", maskedValue: "********68" },
        {
          type: "email",
          value: "kl*********@tu**********.com",
          maskedValue: "kl*********@tu**********.com",
        },
      ],
      records: [],
    },
  ],
};

export const getRecordMock = (vaccineType: string, status: string): ImmunizationRecord => {
  let newRecord = {
    searchUid: "633db5ee-d3d6-422c-98e6-1370a077fb6c",
    patientId: "3204",
    patient: null as unknown as ImmunizationPatient,
    izProviderId: 2,
    izProviderKey: "NJ",
    status,
    vaccineType: vaccineType,
    earliestDateToGive: "2023-05-03T10:33:53.936Z",
    dateDue: "2023-05-03T10:33:53.936Z",
    dateOverdue: "2023-05-03T10:33:53.936Z",
    latestDateToBeGiven: "2023-05-03T10:33:53.936Z",
    events: [],
  };
  return newRecord;
};

/**
 * createSearches
 *
 * @remarks
 * Parses the API response for getIzSearches and returns Array<ImmunizationSearch>
 *
 * @returns promise an array of ImmunizationSearch objects
 *
 */
export const createSearches = (data: ImmunizationSearchAPIData[]): ImmunizationSearch[] => {
  const newSearches = data.map(searchBuilder);
  return newSearches;
};

export const searchBuilder = (search: ImmunizationSearchAPIData) => {
  const newSearch = search.attributes as ImmunizationSearchAttributes;
  const theSearch: interfaces.ImmunizationSearch = {
    firstName: newSearch.first_name ?? "",
    lastName: newSearch.last_name ?? "",
    dateOfBirth: newSearch.dob ?? "",
    legalSex: newSearch.legal_sex,
    childDateOfBirth: newSearch.child_dob ?? "",
    childFirstName: newSearch.child_first_name ?? "",
    childLastName: newSearch.child_last_name ?? "",
    childLegalSex: newSearch.child_legal_sex ?? "",
    dateUpdated: newSearch.updated_at ?? "",
    dateVerified: newSearch.verified_at ?? "",
    patients: createPatients(
      newSearch.patients,
      newSearch.uid,
      newSearch.iz_provider_id,
      newSearch.iz_provider_key
    ),
    contacts: [] as ImmunizationPatientContact[],
    recordsFound: 0,
    status: newSearch.status,
    whoAmI: newSearch.who_am_i ?? "",
    uid: newSearch.uid,
    izProviderKey: newSearch.iz_provider_key,
    izProviderId: newSearch.iz_provider_id,
  };

  theSearch.contacts = theSearch.patients.flatMap((patient) => {
    if (patient.contacts) {
      return patient.contacts;
    } else {
      return [];
    }
  });
  return theSearch;
};

/**
 * createPatients
 *
 * @remarks
 * Parses the API response for getIzSearches patient data, PatientAPI[], and
 * returns Array<ImmunizationPatient>
 *
 * @returns promise an array of ImmunizationPatient objects
 *
 */
const createPatients = (
  patients: PatientAPI[],
  searchUid: string,
  izProviderId: number,
  izProviderKey: string
): ImmunizationPatient[] => {
  let result: ImmunizationPatient[] = [] as ImmunizationPatient[];
  if (!patients || patients.length === 0) {
    return result;
  }

  return patients.map((p) => {
    return {
      firstName: p.first_name ?? "",
      lastName: p.last_name ?? "",
      patientId: p.patient_id ?? "",
      dob: p.dob ?? "",
      searchUid: searchUid,
      izProviderId: izProviderId,
      izProviderKey: izProviderKey,
      contacts: setNewPatientContacts(p.phone_numbers, p.email_addresses),
    };
  });
};

const setNewPatientContacts = (phoneNumbers: Array<string>, emailAddresses: Array<string>) => {
  const contacts = Array<ImmunizationPatientContact>();
  if (phoneNumbers && phoneNumbers.length > 0) {
    phoneNumbers.forEach((phone) => {
      contacts.push({ type: "phone", value: phone, maskedValue: phone });
    });
  }
  if (emailAddresses && emailAddresses.length > 0) {
    emailAddresses.forEach((email) => {
      contacts.push({ type: "email", value: email, maskedValue: email });
    });
  }
  return contacts;
};

export const createNewRecord = (
  record: Immunization,
  searchUid: string,
  recordsUid: string,
  patient: ImmunizationPatient
): ImmunizationRecord => {
  let events: ImmunizationEvent[] = JSON.parse(JSON.stringify(record.events));
  let eventsArray = [] as ImmunizationEvent[];
  events.forEach((event) => {
    let addressInfo: ImmunizationAdministrationLocation = JSON.parse(
      JSON.stringify(event.administered_at_location)
    );
    let newEvent = {
      date_administered: event?.date_administered,
      manufacturer: event?.manufacturer,
      manufacturer_name: event?.manufacturer_name,
      lot: event?.lot,
      administered_at_location: {
        facility: addressInfo ? addressInfo.facility : "",
        street: addressInfo ? addressInfo.street_address : "",
        city: addressInfo ? addressInfo.city : "",
        state: addressInfo ? addressInfo.state : "",
        zip: addressInfo ? addressInfo.zip : "",
      },
      cvx_code: event?.cvx_code ?? "",
      cpt_code: event?.cpt_code ?? "",
      dose_status: event?.dose_status ?? "",
      description: event?.description ?? "VACCINE",
    };
    eventsArray.push(newEvent);
  });
  let newRecord = {
    searchUid: patient.searchUid,
    recordsUid,
    patientId: patient.patientId,
    patient: null as unknown as ImmunizationPatient,
    izProviderId: patient.izProviderId,
    izProviderKey: patient.izProviderKey,
    status: record.status,
    vaccineType: record.vaccine_type,
    earliestDateToGive: record.earliest_date_to_give ?? "",
    dateDue: record.date_vaccination_due ?? "",
    dateOverdue: record.date_dose_is_overdue ?? "",
    latestDateToBeGiven: record.latest_date_next_dose_should_be_given ?? "",
    events: eventsArray,
  };
  return newRecord;
};

export const setDefaultReports = (firstName: string, lastName: string) => {
  return reports
    .map((report) => `${report}_${firstName}_${lastName}`)
    .reduce((arr, report) => {
      arr.push({ reportName: report, reportStatus: "available", filePath: "" });
      return arr;
    }, [] as ImmunizationPatientReport[]);
};
