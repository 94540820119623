import { ImmunizationEvent, ImmunizationProviderConfig, LegalSex } from "../apiClient";
import { ITokenProvider } from "./TokenProvider";

export interface ImmunizationsState {
  gaveConsent: Boolean;
  patients: ImmunizationPatient[];
  reports: ImmunizationPatientReport[];
  searches: ImmunizationSearch[];
  search: ImmunizationSearch;
  verifiedSearchId: Number;
  pinAttempts: Number;
  error: string;
  searchQueue: ImmunizationSearchesEnqueued[];
  recordsQueue: ImmunizationRecordsEnqueued[];
  updateRecordsQueue: ImmunizationRecordsEnqueued[];
  providerConfigs: ImmunizationProviderConfig[];
  njFeatureFlag: boolean;
  providerLabels: string[];
}

export interface ImmunizationNewSearch {
  firstName: string;
  lastName: string;
  legalSex: string;
  dateOfBirth: string;
  childFirstName?: string;
  childLastName?: string;
  childLegalSex?: string;
  childDateOfBirth?: string;
  izProviderKey: string;
  whoAmI: string;
}

export interface ImmunizationSearch {
  firstName: string;
  lastName: string;
  legalSex: string;
  dateOfBirth: string;
  childFirstName: string;
  childLastName: string;
  childLegalSex: string;
  childDateOfBirth: string;
  dateUpdated: string;
  dateVerified: string;
  uid: string;
  izProviderId: number;
  izProviderKey: string;
  whoAmI: string;
  status: string;
  recordsFound: number;
  patients: ImmunizationPatient[];
  contacts?: ImmunizationPatientContact[];
}

export interface ImmunizationSearchesEnqueued {
  estimated_wait_time: number;
  // TODO Do something with this?
  uid: string;
}

export interface ImmunizationRecordsEnqueued {
  estimated_wait_time: number;
  // TODO Do something with this?
  uid: string;
}

export interface ImmunizationRecord {
  dateDue: string;
  dateOverdue: string;
  earliestDateToGive: string;
  latestDateToBeGiven: string;
  patientId: string;
  searchUid: string;
  izProviderId: number;
  status: string;
  vaccineType: string;
  izProviderKey: string;
  events?: ImmunizationEvent[];
  patient: ImmunizationPatient;
}

// export interface ImmunizationRecordEvent {
//   dateAdministered?: string;
//   doseNumberInSeries?: string;
//   manufacturer?: string;
//   recordId: string;
//   administeredAtFacility?: string;
//   administeredAtStreet?: string;
//   administeredAtCity?: string;
//   administeredAtState?: string;
//   administeredAtZip?: string;
//   record: ImmunizationRecord;
// }

export interface ImmunizationPatient {
  firstName: string;
  lastName: string;
  patientId: string;
  userId?: number;
  dob: string;
  searchUid: string;
  izProviderId: number;
  izProviderKey: string;
  records?: ImmunizationRecord[];
  searches?: ImmunizationSearch[];
  screenings?: ScreeningRecord[];
  contacts?: ImmunizationPatientContact[];
}

export interface ImmunizationPatientContact {
  type: string;
  value: string;
  maskedValue?: string;
}

export interface ImmunizationPatientReport {
  reportName: string;
  reportStatus: string;
  filePath: string;
}

export interface UserNotificationPreferences {
  disable_all: boolean;
  email_upcoming_vaccines: boolean;
  email_app_updates: boolean;
  email_new_results: boolean;
  push_upcoming_vaccines: boolean;
  push_app_updates: boolean;
  push_new_results: boolean;
}

export interface Tokens {
  access: string;
  refresh: string;
}

export class UserAccount {
  tokens?: Tokens;
  email?: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  verified_phone_number?: string;
  dob?: string;
  legal_sex?: LegalSex;
  language?: string;
  opt_out_lmr?: boolean;
  notification_preferences?: UserNotificationPreferences;
  sign_hipaa_electronically?: boolean;
  client_key?: string;

  isLoggedIn(): boolean {
    return !!this.tokens && this.tokens.access?.length > 0;
  }

  tokenExpired(tokenProvider: ITokenProvider): boolean {
    return tokenProvider.tokenExpired(this.tokens?.access || "");
  }
}

// This specifically must be a class so we can use `instanceof` to check what object we're dealing with
// when trying to figure out what our capabilities are (i.e. storing and encrypting the client_key).
// This is specifically for database.ts; see that file for more info.
export class UserAccountWithPassword extends UserAccount {
  password?: string;
}

export interface UserAccountResponse {
  user: UserAccount;
}

export interface Login {
  email: string;
  password: string;
}

export interface ForgotPassword {
  email: string;
}

export interface Signup {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  password: string;
  passwordConfirmation: string;
}

export interface AppleSignUp {
  token: string;
  code: string;
  email?: string;
  first_name?: string;
  last_name?: string;
}

export interface GoogleSignup {
  email: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  token: string;
  google_id: string;
  offset: string; //NSTimeZone.system.secondsFromGMT() equivalent
}

export interface AppleOauthResponse {
  error: { message: string; stack: string };
  authorization?: { code: string; id_token: string };
  user?: {
    email: string;
    name: { first_name: string; last_name: string };
  };
}

export interface ScreeningRecord {
  date: string | null;
  patientId: string;
  searchUid: string;
  izProviderId: number;
  type: string;
  izProviderKey: string;
  events: ScreeningEvent[];
  patient: ImmunizationPatient;
}

export enum ContactMethodEnum {
  Unknown = "UNKNOWN",
  Email = "Email",
  Phone = "Phone",
}

interface ContactInfo {
  method: ContactMethodEnum;
  value: string;
}

interface OrderingFacility {
  names: string[];
  contactInfo: ContactInfo[];
}

export interface ScreeningEvent {
  // Specifically a date, e.g. 1990-01-30
  testDate: string | null;
  type: string;
  measurement: number;
  laboratory: string | null;
  units: string;
  orderingFacility: OrderingFacility;
}

export enum ScreeningType {
  BLOOD_LEAD = "BLOOD_LEAD",
  BLOOD_LEAD_CAPILLARY = "BLOOD_LEAD_CAPILLARY",
  BLOOD_LEAD_VENOUS = "BLOOD_LEAD_VENOUS",
}

export enum SearchStatus {
  match = "MATCH",
  noMatch = "NO_MATCH",
  partialMatchNoContacts = "PARTIAL_MATCH_NO_CONTACTS",
  partialMatchAltContacts = "PARTIAL_MATCH_ALT_CONTACTS",
  basicMatchNoContacts = "BASIC_MATCH_NO_CONTACTS",
  multiMatch = "MULTI_MATCH",
  noRecords = "NO_RECORDS",
  inQueue = "IN_QUEUE",
}
