import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LoginState {
  email: string;
  password: string;
  authorized: boolean;
  firebaseEmulatorConnected: boolean;
  signedInWithFirebase: boolean;
}

export const initialState: LoginState = {
  email: "",
  password: "",
  authorized: false,
  firebaseEmulatorConnected: false,
  signedInWithFirebase: false,
};

export const cryptoLoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setAuthorized: (state, action: PayloadAction<boolean>) => {
      state.authorized = action.payload;
    },
    setFirebaseEmulatorConnected: (state, action: PayloadAction<boolean>) => {
      state.firebaseEmulatorConnected = action.payload;
    },
    setSignedInWithFirebase: (state, action: PayloadAction<boolean>) => {
      state.signedInWithFirebase = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setEmail,
  setPassword,
  setAuthorized,
  setFirebaseEmulatorConnected,
  setSignedInWithFirebase,
  reset,
} = cryptoLoginSlice.actions;

export const selectEmail = (state: RootState) => state.login.email;
export const selectPassword = (state: RootState) => state.login.password;

export default cryptoLoginSlice.reducer;
