import {
  UserAccount,
  GoogleSignup,
  AppleSignUp,
  Login,
  Tokens,
  ScreeningEvent,
} from "./models/Interfaces";
import { enumKeys, LEGACY_API_DOMAIN } from "./globals";
import axios from "axios";
import ChainedCustomError from "typescript-chained-error";
import { API_DOMAIN, PROTOCOL } from "./globals";
import db, { Key } from "./database";
import VERSION from "./version";
import _ from "lodash";
import { ITokenProvider, RefreshingTokenProvider } from "./models/TokenProvider";
import { apiV2Route } from "./apiV2Route";
import logout from "./utils/logout";
import { getAuth } from "firebase/auth";

export interface IDocketAPI {
  /**
   * The protocol to make API requests over (e.g. https)
   */
  readonly protocol: string;

  /**
   * Get the host name for the API
   * @returns the hostname
   */
  readonly hostname: string;

  /**
   * Feed in a function and it will be called whenever we are getting user details to make a request.
   */
  setRequestingUserFn(userRetrievalFn: () => Promise<UserAccount | null>): void;

  /**
   * Attempt to create a user.
   * @param userInfo The signup data for the user
   * @return A UserAccount on success. Rejected promises will have an APIError.
   */
  createUser(userInfo: SignupRequest): Promise<UserAccount>;

  /**
   * Get the user data with the current login credentials.
   * @return the user
   */
  getUser(): Promise<UserAccount>;

  /**
   * Deletes the currently logged-in user along with everything about their account (e.g. searches, histories, preferences)
   */
  deleteUser(): Promise<Status>;

  /**
   * Update the user. This can be any partial number of attributes that are part of the UserAccount.
   * @return true
   */
  updateUser(params: any): Promise<Status>;

  /**
   * Login the user.
   * @param loginInfo The login info
   * @return A UserAccount with a token on success. Rejected promises will have an APIError.
   */
  login(loginInfo: Login): Promise<UserAccount>;

  /**
   * Send magic link
   * @param email The email
   * @return void
   */
  sendSignInLink(email: string): Promise<void>;

  /**
   * Login the user with magic link.
   * @param token firebase token
   * @return A UserAccount with a token on success. Rejected promises will have an APIError.
   */
  loginWithFirebase(token: string): Promise<UserAccount>;

  /**
   * Refresh the bearer and refresh token with the current refresh token.
   * This effectively re-authenticates the user.
   *
   * This operation MUST save the resulting tokens as a side-effect for re-use
   * in subsequent requests.
   *
   * @return The updated tokens
   */
  refreshTokens(): Promise<Tokens>;

  /**
   * Deletes refresh token. This should be done on log out to ensure the token family is cleaned up.
   * @return A resolved promise once the call is completed, rejected on error.
   */
  deleteToken(): Promise<void>;

  /**
   * Initiates login flow to the correct OIDC provider depending on state
   *
   * @param stateIdentifier state identifier, two letters (example: ak, ut)
   * @return void
   */
  initiateOidcAuth(stateIdentifier: string): Promise<void>;

  /**
   * Attempt to log in user from OIDC flow
   *
   * @param code string with code to use on auth flow
   * @param stateIdentifier state identifier, two letters (example: ak, ut)
   * @return A UserAccount object with a token on success.  Rejected promises will have an APIError
   */
  loginWithAuthCode(code: string, stateIdentifier: string): Promise<UserAccount>;

  /**
   * Attempt to verify that the user actually owns the phone number they pass in.
   *
   * @param phoneNumber The phone number to verify at the account level. Should be 10 digits, matching regex [0-9]{10}.
   * @return A promise that will resolve if the call was successful. This does NOT indicate
   * that the text message was successfully delivered, but that the backend considered this
   * an authorized request.
   */
  addUserAccountPhone(phoneNumber: PhoneNumber): Promise<void>;

  /**
   * This is the other half of `requestUserAccountPhone`.
   * @param code The verification code
   * @return An accepted promise if the code was valid, otherwise rejected.
   */
  verifyUserAccountPhone(code: PhoneVerificationCode): Promise<void>;

  /**
   * Get all the immunization searches for a user.
   * @return A list of all the searches
   */
  getIzSearches(iconTapped: boolean): Promise<ImmunizationSearchAPI>;

  /**
   * Send a request to queue an immunization search
   * @param request The request
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return A promise with info about the queue
   */
  enqueueIzSearch(
    request: ImmunizationSearchRequestAPI,
    jurisdiction: string
  ): Promise<ImmunizationEnqueuedResponseAPI>;

  /**
   * Get immunization search data
   * @param searchUid The search ID
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return Immunization search data, or void if the request isn't yet ready (e.g. still processing in backend queues)
   */
  getIzSearch(searchUid: string, jurisdiction: string): Promise<void | ImmunizationSearchAPIData>;

  /**
   * Delete an immunization search
   * @param searchUid The search ID
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return A status with "ok" if it was successful, 400 otherwise, including if the search was already deleted.
   */
  deleteIzSearch(searchUid: string, jurisdiction: string): Promise<Status>;

  /**
   * Send a verification code for a specific immunization search. At least one of SMS, email, and voice are required.
   * @param searchUid The UID of the search
   * @param requestParameters The parameters
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return "OK" if the PIN was sent.
   */
  sendImmunizationSearchPIN(
    searchUid: string,
    requestParameters: ImmunizationSearchPINRequest,
    jurisdiction: string
  ): Promise<Status>;

  /**
   * The other half of `sendImmunizationSearchPIN`. A user should supply the PIN, and this will verify the search.
   * @param searchUid The search UID to verify
   * @param pin The PIN from the user that should match whatever was sent.
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return "OK" and the time if the PIN was verified successfully.
   */
  verifyImmunizationSearchPIN(
    searchUid: string,
    pin: string,
    jurisdiction: string
  ): Promise<PINVerificationResponse>;

  /**
   * Verify a provider-issued PIN. This only applies as of 2022-01-05 to Utah, which gives PINs physically
   * to people.
   * @param searchUid The search ID
   * @param pin The PIN
   * @param patientId The patient ID the PIN applies to in the search record.
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return "OK" and the time if the PIN was verified successfully
   */
  verifyImmunizationProviderPIN(
    searchUid: string,
    pin: string,
    patientId: string,
    jurisdiction: string
  ): Promise<PINVerificationResponse>;

  /**
   * Get the immunization history for a corresponding search. A bit weird that we use the *search ID*
   * instead of the history ID.
   * @param searchUid The IZ search ID
   * @param jurisdiction The jurisdiction the search and corresponding record belongs to (eg 'nj', 'ut', 'ak')
   * @return
   */
  getIzRecord(searchUid: string, jurisdiction: string): Promise<ImmunizationRecordAPIData>;

  /**
   * Get immunization search data
   * @param searchUid The search ID
   * @param jurisdiction The jurisdiction the search belongs to (eg 'nj', 'ut', 'ak')
   * @return Immunization Screening data, or void if the request isn't yet ready (e.g. still processing in backend queues)
   */
  getIzScreeningRecord(searchUid: string, jurisdiction: string): Promise<ScreeningEvent[]>;

  /**
   * Return a list of all available records for the user.
   * @return A promise with all the records
   */
  getAllIzRecords(): Promise<ImmunizationRecordsAPI>;

  /**
   * Ask the server to call the IIS and refresh the records.
   * @return The estimated wait time to refresh the records in seconds.
   */
  refreshRecords(): Promise<number>;

  /**
   * Get a PDF report of immunization data.
   * @param searchUid The immunization search ID.
   * @param reportType The type of PDF report to get.
   * @param jurisdiction The jurisdiction the search and corresponding record belongs to (eg 'nj', 'ut', 'ak')
   * @return A buffer of binary PDF data on success.
   */
  getRecordReport(
    searchUid: string,
    reportType: ReportType,
    jurisdiction: string
  ): Promise<ArrayBuffer>;

  /**
   * Get the VCI QR code for a specific history ID.
   * @param recordUid The record UID for an immunization history
   * @param contentType Use SVG for a rendered version or Numeric for a smaller transfer but that requires more processing in the app
   * @param vaccineType The type of vaccine to request a QR code for. Typically this is "COVID".
   * @param jurisdiction The jurisdiction the record belongs to (eg 'nj', 'ut', 'ak')
   * @return
   */
  getVCICode(
    recordUid: string,
    contentType: VCIContentType,
    vaccineType: string,
    jurisdiction: string
  ): Promise<VCIResponse>;

  /**
   * Get all of the IIS provider configurations. This should dynamically change what's available in the app
   * at run time.
   * @return A list of all of the configurations
   */
  getProviderConfigs(): Promise<ImmunizationProviderConfigResponse>;
}

export enum LegalSex {
  Male = "M",
  Female = "F",
  Unknown = "U",
  Other = "X",
}

export function legalSexFromString(legalSex?: string): LegalSex {
  if (!legalSex) {
    return LegalSex.Unknown;
  }

  //should return from this block for legalSex from search API call
  const upperLegalSex = legalSex.toUpperCase();
  for (const value of Object.values(LegalSex)) {
    if (upperLegalSex === value) {
      return upperLegalSex;
    }
  }

  //else we arrive here for login legal sex match which is full word...
  const fullLegalSexToEnum: Record<string, LegalSex> = {
    MALE: LegalSex.Male,
    FEMALE: LegalSex.Female,
    UNKNOWN: LegalSex.Unknown,
    OTHER: LegalSex.Other,
  };
  for (const key of Object.keys(fullLegalSexToEnum)) {
    if (key === upperLegalSex) {
      return fullLegalSexToEnum[key];
    }
  }
  throw new Error(`Could not determine legal sex for string '${legalSex}'`);
}

export enum MatchStatus {
  NoMatch = "NO_MATCH",
  Match = "MATCH",
  BasicMatchNoContacts = "BASIC_MATCH_NO_CONTACTS",
  PartialMatchAltContacts = "PARTIAL_MATCH_ALT_CONTACTS",
  PartialMatchNoContacts = "PARTIAL_MATCH_NO_CONTACTS",
  MultiMatch = "MULTI_MATCH",
  InQueue = "IN_QUEUE",
}

export function matchStatusFromString(matchStatus?: string): MatchStatus {
  if (!matchStatus || !Object.values(MatchStatus).includes(matchStatus as MatchStatus)) {
    return MatchStatus.NoMatch;
  }
  for (const value of Object.values(MatchStatus)) {
    if (matchStatus.toUpperCase() === value) {
      return value;
    }
  }
  throw new Error(`Could not find a match status for '${matchStatus}'`);
}

export enum ReportType {
  HISTORY_REPORT = "history_report", // default
  SCHOOL_REPORT = "school_report",
  FORECAST_REPORT = "forecast_report",
  PERSONAL_REPORT = "personal_report",
}

// TODO: Make sure these enum converters work as expected
export function reportTypeFromString(reportType: string): ReportType {
  const rt = reportType.toLowerCase();
  for (const key of enumKeys(ReportType)) {
    const val = ReportType[key];
    if (rt === val) {
      return val;
    }
  }
  throw new Error(`Could not find a report type that matches '${reportType}'`);
}

class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedError";
  }
}

export enum VCIContentType {
  SVG = "image/svg", // No longer supported
  PNG = "image/png",
  Numeric = "qr/numeric",
  Default = "application/json",
}

export interface PINVerificationResponse {
  status: string;
  verified_at: string;
}

export interface SignupRequestBody {
  email: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  password: string;
  password_confirmation: string;
  offset?: string;
}

export interface SignupRequest {
  user: SignupRequestBody | AppleSignUp | GoogleSignup;
}

export interface ImmunizationSearchRequestAPI {
  first_name?: string;
  last_name?: string;
  /**
   * Must be ISO-8601 formatted (e.g. 1990-01-31 / YYYY-MM-DD)
   */
  dob?: string;
  legal_sex: LegalSex;
  child_first_name?: string;
  child_last_name?: string;
  child_dob?: string;
  child_legal_sex?: LegalSex;
  // TODO: Add an enum for providers
  iz_provider_key: string;
  who_am_i: string;
}

export interface ImmunizationEnqueuedResponseAPI {
  // Seconds
  estimated_wait_time: number;
  uid: string;
}

export interface ImmunizationSearchAttributes {
  uid: string;
  iz_provider_id: number;
  iz_provider_key: string;
  child_dob?: string;
  child_first_name?: string;
  child_last_name?: string;
  child_legal_sex?: LegalSex;
  dob: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  legal_sex: LegalSex;
  status: MatchStatus;
  user_id: number;
  updated_at?: string;
  created_at: string;
  discarded_at?: string;
  verified_at?: string;
  dequeued_at?: string;
  patients: PatientAPI[];
  who_am_i?: string;
}

export interface PatientAPI {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  legal_sex: LegalSex;
  dob: string;
  phone_numbers: string[];
  email_addresses: string[];
  addresses: Address[];
  match_status?: string;
  patient_id: string;
  patient_type_code: string;
}

export interface Address {
  street: string;
  city: string;
  state: string;
  postal_code: string;
}

export interface PhoneNumber {
  phone_number: string;
}

export interface Status {
  status: string;
}

export interface PhoneVerificationCode {
  code: string;
}

export interface APIError {
  errors: string[];
}

export interface ImmunizationSearchAPI {
  data: ImmunizationSearchAPIData[];
}

export interface ImmunizationSearchAPIData {
  status?: number;
  id: number;
  type: string;
  uid: string;
  attributes: ImmunizationSearchAttributes;
}

export interface ImmunizationRecordsAPI {
  data: ImmunizationRecordsAPIData[];
}

/**
 * SMS, Email, and Voice are mutually exclusive. Only one should be specified.
 */
export interface ImmunizationSearchPINRequest {
  sms?: string;
  email?: string;
  voice?: string;
}

export interface ImmunizationRecordsAPIData {
  id: number;
  uid: string;
  type: string;
  attributes: ImmunizationRecordAttributes[];
}

export interface ImmunizationRecordAPIData {
  id: number;
  type: string;
  attributes: ImmunizationRecordAttributes;
}

export interface ImmunizationRecordAttributes {
  id: number;
  uid: string;
  iz_search_id: number;
  iz_search_uid: string;
  iz_provider_id: number;
  iz_provider_key: string;
  patient_id: string;
  updated_at?: string;
  created_at: string;
  discarded_at?: string;
  dequeued_at?: string;
  immunizations: Immunization[];
}

export interface Immunization {
  earliest_date_to_give?: string;
  date_vaccination_due?: string;
  latest_date_next_dose_should_be_given?: string;
  date_dose_is_overdue?: string;
  status: string;
  vaccine_type: string;
  events: ImmunizationEvent[];
}

export interface ImmunizationEvent {
  // Specifically a date, e.g. 1990-01-30
  date_administered: string;
  manufacturer: string;
  manufacturer_name: string;
  lot: string;
  administered_at_location: ImmunizationAdministrationLocation;
  cvx_code: string;
  cpt_code?: string;
  dose_status: string;
  dose_number_in_series?: string;
  // administeredAtFacility?: string;
  // administeredAtStreet?: string;
  // administeredAtCity?: string;
  // administeredAtState?: string;
  // administeredAtZip?: string;
  description?: string;
}

export interface ImmunizationAdministrationLocation {
  facility: string;
  street_address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface VCIResponse {
  /**
   * This is used for the default content type
   */
  verifiableCredential?: string[];
  /**
   * This is used by the numeric content type
   */
  shc?: string;
  /**
   * This is used when receiving an SVG
   */
  svg?: ArrayBuffer;
}

export interface ImmunizationProviderConfigResponse {
  configs: ImmunizationProviderConfig[];
}

export interface ImmunizationProvider {
  destination_id: string;
  // IIS authority name, typically. E.g. "Alaska", "New Jersey", "Philadelphia"
  name: string;
}

/*
  Looks like
  {
      "auth_method": "http_basic",
      "iz_provider_id": "ts",
      "additional_fields": {
          "ssn": {
              "required": false
          },
          "address": {
              "required": true
          }
      },
      "enabled": true,
      "features": {
          "qr_code": true
      },
      "iz_provider": {
          "destination_id": "ts",
          "name": "Test"
      }
  }
   */

export interface ImmunizationProviderConfigFeatures {
  covid_toggle?: boolean;
  qr_code?: boolean;
  pwa_enabled?: boolean;
}

export interface ImmunizationProviderConfig {
  // "iz_gateway" or "http_basic" today
  auth_method: string;
  iz_provider_id: string;
  iz_provider: ImmunizationProvider;
  enabled?: boolean;
  additional_fields?: any;
  features?: ImmunizationProviderConfigFeatures;
}

export class DocketAPIError extends ChainedCustomError {
  public constructor(msg?: string, cause?: Error) {
    super(msg, cause);
  }
}

export class DocketAPIAlreadyEnqueuedError extends DocketAPIError {
  public constructor(msg: string) {
    super(msg);
  }
}

export class DocketCareAPIV1 implements IDocketAPI {
  readonly hostname: string;
  readonly protocol: string;
  readonly #headers: { [key: string]: string };
  readonly #default_axios_options: any;
  #userRetrievalFn: () => Promise<UserAccount | null>;
  #tokenProvider: ITokenProvider;

  constructor(hostname: string, protocol = "https", tokenProvider?: ITokenProvider) {
    this.hostname = hostname;
    this.protocol = protocol;
    //const ver = "2.1.7d500";
    this.#headers = {
      "Content-Type": "application/json",
      Accept: "*/*",
    };
    this.#default_axios_options = {
      headers: this.#headers,
      responseType: "json",
    };
    this.#userRetrievalFn = async () => null;
    if (tokenProvider) {
      this.#tokenProvider = tokenProvider;
    } else {
      this.#tokenProvider = new RefreshingTokenProvider((): Promise<Tokens> => {
        // We can't pass only `this.refreshTokens` through since it uses a private
        // field, and that will blow up at run time. Closure it is.
        return this.refreshTokens();
      });
    }
  }

  private apiPath(path: string): string {
    return `${this.protocol}://${this.hostname}/api/${path}`;
  }

  private throwResponseError(err: any, defaultErrMsg: string) {
    if (err && err.response && err.response.data) {
      if (err.response.data["error"]) {
        throw new DocketAPIError(err.response.data["error"], err);
      } else if (err.response.data["errors"]) {
        throw new DocketAPIError(err.response.data["errors"], err);
      }
    }
    throw new DocketAPIError(defaultErrMsg, err);
  }

  // ==== User functions
  setRequestingUserFn(userRetrievalFn: () => Promise<UserAccount | null>): void {
    this.#userRetrievalFn = userRetrievalFn;
  }

  private async setRequestingUser() {
    const account = await this.#userRetrievalFn();
    if (account === null || !account?.tokens?.access) {
      return;
    }

    // "Why do we need a token provider at all?" you may ask.
    // Well, most technically, we don't.
    // However, let's say our backend is down. Almost all operations after a user is signed in
    // requires authorization. This means lots of tokens.
    // Imagine it's down for long enough that all current access tokens expire.
    // Now, lots of people are trying to get new tokens all at once for many operations,
    // potentially in parallel.
    // We want to not only slow the requests down, but also prevent N API calls for the same thing (duplicated work)
    // The token provider handles state and ensures we're backing off correctly.
    let tokens: Tokens | null = account.tokens;
    if (account.tokenExpired(this.#tokenProvider)) {
      // This is fed in `#refreshTokens` from the API to get the tokens
      // `#refreshTokens` will re-save the everything to the user account,
      // but we just need it for the next request
      tokens = await this.#tokenProvider.tokens();
    }

    if (!tokens) {
      return;
    }

    this.#headers["Authorization"] = `Bearer ${tokens.access}`;
    this.#headers["User-Agent"] = `DocketPWA/1.0-${VERSION}`;
    this.#headers["X-User-Agent"] = `DocketPWA/1.0-${VERSION}`;
    this.#headers["X-User-Email"] = account.email || "";
    this.#default_axios_options["headers"] = this.#headers;
  }

  async refreshTokens(): Promise<Tokens> {
    const account = await this.#userRetrievalFn();
    try {
      const response = await axios.post(
        this.apiPath("auth/tokens"),
        { refreshToken: account?.tokens?.refresh },
        this.#default_axios_options
      );

      const result = response.data;

      if (result !== null) {
        const userAccount = Object.assign(new UserAccount(), { ...account, tokens: result });
        await persistUserToStorage(userAccount);
        await this.setRequestingUser();
        return result;
      } else {
        throw new DocketAPIError("Refresh token request failed.");
      }
    } catch (err) {
      this.throwResponseError(err, "Failed to refresh the token.");
      // We'll never reach this line, but TS isn't smart enough to know that
      // control flow goes through throwResponseError which will always throw.
      // `throw err` makes it happy.
      throw err;
    }
  }

  async sendSignInLink(email: string): Promise<void> {
    try {
      await axios.post<void>(
        this.apiPath(`auth/magic_link`),
        { email: email },
        this.#default_axios_options
      );
    } catch (err) {
      this.throwResponseError(err, "Sorry, failed to send Signin link.");
    }
    return;
  }

  async loginWithFirebase(token: string): Promise<UserAccount> {
    let result: UserAccount | null = null;
    try {
      result = (
        await axios.post(
          this.apiPath("user/firebase_auth"),
          { token: token },
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, you were unable to login. Please try again later.");
    }

    if (result !== null) {
      // Super fun: legal sex is returned as a full string, e.g. "male", instead of "M" for this API.
      result["legal_sex"] = legalSexFromString(result["legal_sex"]);
    }
    return Object.assign(new UserAccount(), result);
  }

  async createUser(userInfo: SignupRequest): Promise<UserAccount> {
    let result: UserAccount | null = null;
    let signUpPath = "user";
    if ("google_id" in userInfo.user) {
      signUpPath = "user/google_auth";
    } else if ("code" in userInfo.user) {
      signUpPath = "user/apple_auth";
    }

    try {
      result = (
        await axios.post<UserAccount>(
          this.apiPath(signUpPath),
          userInfo,
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, your sign up failed. Please try again later.");
    }
    return Object.assign(new UserAccount(), result);
  }

  async getUser(): Promise<UserAccount> {
    await this.setRequestingUser();
    let result: UserAccount | null = null;
    try {
      const result = (
        await axios.get<UserAccount>(
          this.apiPath(apiV2Route({ preJurisdiction: ["user"] })),
          this.#default_axios_options
        )
      ).data;
      return result;
    } catch (err: any) {
      this.throwResponseError(
        err,
        "Sorry, Docket could not get your user information at this time. Please try again later."
      );
    }
    return Object.assign(new UserAccount(), result);
  }

  async login(loginInfo: Login): Promise<UserAccount> {
    let result: UserAccount | null = null;
    try {
      result = (
        await axios.post(this.apiPath("auth"), { user: loginInfo }, this.#default_axios_options)
      ).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, you were unable to login. Please try again later.");
    }

    if (result !== null) {
      // Super fun: legal sex is returned as a full string, e.g. "male", instead of "M" for this API.
      result["legal_sex"] = legalSexFromString(result["legal_sex"]);
    }
    return Object.assign(new UserAccount(), result);
  }

  async initiateOidcAuth(stateIdentifier: string): Promise<void> {
    window.location.href = `${PROTOCOL}://${LEGACY_API_DOMAIN}/auth/${stateIdentifier}`;
    return Promise.resolve();
  }

  async loginWithAuthCode(code: string, stateIdentifier: string): Promise<UserAccount> {
    let result: UserAccount | null = null;
    try {
      result = (
        await axios.post(
          `${this.apiPath("auth")}/${stateIdentifier}`,
          { code: code },
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      this.throwResponseError(err, "Sorry, you were unable to login. Please try again later.");
    }

    if (result !== null) {
      // Super fun: legal sex is returned as a full string, e.g. "male", instead of "M" for this API.
      result["legal_sex"] = legalSexFromString(result["legal_sex"]);
    }
    return Object.assign(new UserAccount(), result);
  }

  async refreshToken(refreshToken: string): Promise<UserAccount> {
    try {
      const response = await axios.post(
        this.apiPath("auth/tokens"),
        { refreshToken: refreshToken },
        this.#default_axios_options
      );

      const result = response.data;

      if (result !== null) {
        return result;
      } else {
        throw new DocketAPIError("Refresh token request failed.");
      }
    } catch (err) {
      this.throwResponseError(err, "Failed to refresh the token.");
      throw err; // Rethrow the error to be handled by the caller
    }
  }

  async deleteToken(): Promise<any> {
    let result;
    const account = await this.#userRetrievalFn();
    try {
      result = (
        await axios.delete(this.apiPath("auth/tokens"), {
          data: { refreshToken: account?.tokens?.refresh },
          ...this.#default_axios_options,
        })
      ).data;
    } catch (err) {
      this.throwResponseError(err, "Docket was unable to revoke your tokens.");
    }
    return result;
  }

  async addUserAccountPhone(phoneNumber: PhoneNumber): Promise<void> {
    await this.setRequestingUser();
    try {
      return await axios.post(
        this.apiPath(apiV2Route({ preJurisdiction: ["user", "verify_phone"] })),
        JSON.stringify(phoneNumber),
        this.#default_axios_options
      );
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to send you a code. Please try again later."
      );
    }
    return;
  }

  async verifyUserAccountPhone(code: PhoneVerificationCode): Promise<void> {
    await this.setRequestingUser();
    try {
      return await axios.post(
        this.apiPath(apiV2Route({ preJurisdiction: ["user", "verify_phone_code"] })),
        code,
        this.#default_axios_options
      );
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to verify that code. Please try again later."
      );
    }
    return;
  }

  async deleteUser(): Promise<Status> {
    await this.setRequestingUser();
    let result: Status = { status: "" };
    try {
      return (
        await axios.delete(
          this.apiPath(apiV2Route({ preJurisdiction: ["user"] })),
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to delete your account at this time. Please try again later."
      );
    }
    return result;
  }

  async updateUser(params: any): Promise<Status> {
    await this.setRequestingUser();
    let result: Status = { status: "" };
    try {
      result = (
        await axios.patch(
          this.apiPath(apiV2Route({ preJurisdiction: ["user"] })),
          params,
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket was unable to update your account. Please try again later."
      );
      throw err; // Re-throw the error to propagate it
    }
    return result;
  }

  // ==== IZ SearchList functions
  async enqueueIzSearch(
    request: ImmunizationSearchRequestAPI,
    jurisdiction: string
  ): Promise<ImmunizationEnqueuedResponseAPI> {
    await this.setRequestingUser();
    let result: ImmunizationEnqueuedResponseAPI = {
      estimated_wait_time: -1,
      uid: "",
    };
    try {
      result = (
        await axios.post(
          this.apiPath(
            apiV2Route({
              preJurisdiction: ["immunizations"],
              jurisdiction: jurisdiction,
              postJurisdiction: ["search", "enqueue"],
            })
          ),
          request,
          this.#default_axios_options
        )
      ).data;
    } catch (err: any) {
      if (err && err.response && err.response.status === 409) {
        throw new DocketAPIAlreadyEnqueuedError(err.response.data["error"]);
      } else {
        this.throwResponseError(
          err,
          "Sorry, Docket could not enqueue your search. Please try again later."
        );
        throw err;
      }
    }
    return result;
  }

  async getIzSearch(
    searchUid: string,
    jurisdiction: string
  ): Promise<void | ImmunizationSearchAPIData> {
    await this.setRequestingUser();
    let result;
    try {
      const response = await axios.get(
        this.apiPath(
          apiV2Route({
            preJurisdiction: ["immunizations"],
            jurisdiction: jurisdiction,
            postJurisdiction: ["search", searchUid],
          })
        ),
        this.#default_axios_options
      );
      // 204 will never return data; it has no body
      if (response.status === 204) {
        // Not done de-enqueing yet
        return;
      }
      // getIzSearch does NOT return an array, but data is a map instead with an APIData in it
      // And for some reason the backend is awful and returns this ID as a string, but the attributes as a number.
      response.data["data"]["id"] = parseInt(response.data["data"]["id"]);
      result = response.data["data"];
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket could not retrieve your search details. Please try again later."
      );
      throw err;
    }
    return result;
  }

  async deleteIzSearch(searchUid: string, jurisdiction: string): Promise<Status> {
    await this.setRequestingUser();
    let response: Status = { status: "" };
    try {
      response = (
        await axios.delete(
          this.apiPath(
            apiV2Route({
              preJurisdiction: ["immunizations"],
              jurisdiction: jurisdiction,
              postJurisdiction: ["search", searchUid],
            })
          ),
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket could not delete your search right now. Please try again later."
      );
    }
    return response;
  }

  async getIzSearches(iconTapped: boolean): Promise<ImmunizationSearchAPI> {
    const path = apiV2Route({
      preJurisdiction: ["immunizations", "search"],
      queryParams: { tapped: `${iconTapped}` },
    });
    await this.setRequestingUser();
    let result: ImmunizationSearchAPI = { data: [] };
    try {
      let r = (await axios.get(this.apiPath(path), this.#default_axios_options)).data;
      if (r.data && r.data.length > 0) {
        for (let i = 0; i < r.data.length; i++) {
          // We have to @ts-ignore this piece because the backend gives us a string
          // This keeps the types happy though
          // @ts-ignore
          r.data[i].id = parseInt(r.data[i].id);
        }
      }
      result = r;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, we're unable to get your searches right now. Please try again later."
      );
      throw err;
    }
    return result;
  }

  async sendImmunizationSearchPIN(
    searchUid: string,
    params: ImmunizationSearchPINRequest,
    jurisdiction: string
  ): Promise<Status> {
    let nonNullParams = params.sms ? 1 : 0;
    nonNullParams += params.email ? 1 : 0;
    nonNullParams += params.voice ? 1 : 0;
    if (nonNullParams !== 1) {
      throw new Error("You can only request one of SMS, Email, or Voice for PIN verification");
    }
    await this.setRequestingUser();
    let result: Status = { status: "" };
    try {
      result = (
        await axios.put(
          this.apiPath(
            apiV2Route({
              preJurisdiction: ["immunizations"],
              jurisdiction: jurisdiction,
              postJurisdiction: ["deliver_pin", searchUid],
            })
          ),
          params,
          this.#default_axios_options
        )
      ).data;
    } catch (err) {
      //return Promise.reject(err?.response?.data['error'] ?? 'Sorry, we could not send a verification PIN. Please try again later.')
      this.throwResponseError(
        err,
        "Sorry, we could not send a verification PIN. Please try again later."
      );
      throw err;
    }
    return result;
  }

  async verifyImmunizationSearchPIN(
    searchUid: string,
    pin: string,
    jurisdiction: string
  ): Promise<PINVerificationResponse> {
    await this.setRequestingUser();
    let result: PINVerificationResponse = { status: "", verified_at: "" };
    try {
      result = (
        await axios.put(
          this.apiPath(
            apiV2Route({
              preJurisdiction: ["immunizations"],
              jurisdiction: jurisdiction,
              postJurisdiction: ["verify_pin", searchUid],
            })
          ),
          {
            pin: pin,
          },
          this.#default_axios_options
        )
      ).data;
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, we could not verify that PIN right now. Please try again later."
      );
    }
    return result;
  }

  async verifyImmunizationProviderPIN(
    searchUid: string,
    pin: string,
    patientId: string,
    jurisdiction: string
  ): Promise<PINVerificationResponse> {
    await this.setRequestingUser();
    let result: PINVerificationResponse = { status: "", verified_at: "" };
    try {
      result = (
        await axios.put(
          this.apiPath(
            apiV2Route({
              preJurisdiction: ["immunizations"],
              jurisdiction: jurisdiction,
              postJurisdiction: ["verify_provider_pin", searchUid],
            })
          ),
          {
            patient_id: patientId,
            pin: pin,
          },
          this.#default_axios_options
        )
      ).data;
      return result; // Return the result from the API request
    } catch (err) {
      this.throwResponseError(err, "Sorry, we could not verify that PIN. Please try again later.");
      return result; // Return the default value in case of an error
    }
  }

  // ==== IZ Record functions
  async getIzRecord(searchUid: string, jurisdiction: string): Promise<ImmunizationRecordAPIData> {
    await this.setRequestingUser();
    let result: ImmunizationRecordAPIData = {
      id: -1,
      type: "",
      attributes: {
        id: -1,
        uid: "",
        iz_search_id: -1,
        iz_search_uid: "",
        iz_provider_id: -1,
        iz_provider_key: "",
        patient_id: "",
        created_at: "",
        immunizations: [],
      },
    };

    try {
      const response = await axios.get(
        this.apiPath(
          apiV2Route({
            preJurisdiction: ["immunizations"],
            postJurisdiction: ["records", searchUid],
            jurisdiction: jurisdiction,
          })
        ),
        this.#default_axios_options
      );
      // Same problem as searches - IDs aren't numeric from the API
      response.data["data"]["id"] = parseInt(response.data["data"]["id"]);
      result = response.data["data"];
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve a specific immunization record. Please try again later."
      );
    }
    return result;
  }

  // ==== Screening functions
  async getIzScreeningRecord(searchUid: string, jurisdiction: string): Promise<ScreeningEvent[]> {
    await this.setRequestingUser();
    let result: ScreeningEvent[] = [];

    try {
      const response = await axios.get(
        this.apiPath(
          apiV2Route({
            preJurisdiction: ["immunizations"],
            postJurisdiction: ["records", searchUid, "screenings"],
            jurisdiction: jurisdiction,
          })
        ),
        this.#default_axios_options
      );
      response.data["screenings"];
      result = response.data["screenings"];
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve a specific immunization record. Please try again later."
      );
    }
    return result;
  }

  async getAllIzRecords(): Promise<ImmunizationRecordsAPI> {
    await this.setRequestingUser();
    let result: ImmunizationRecordsAPI = { data: [] };
    try {
      result = (
        await axios.get(
          this.apiPath(apiV2Route({ preJurisdiction: ["immunizations", "records"] })),
          this.#default_axios_options
        )
      ).data;
      if (result.data && result.data.length > 0) {
        for (let i = 0; i < result.data.length; i++) {
          // We have to @ts-ignore this piece because the backend gives us a string
          // This keeps the types happy though
          // @ts-ignore
          result.data[i].id = parseInt(result.data[i].id);
        }
      }
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve all of your records right now. Please try again later."
      );
      throw err;
    }
  }

  async getRecordReport(
    searchUid: string,
    reportType: ReportType,
    jurisdiction: string
  ): Promise<ArrayBuffer> {
    await this.setRequestingUser();

    let result: Promise<ArrayBuffer> = (async () => {
      return new ArrayBuffer(0);
    })();
    try {
      const headers = { ...this.#headers };
      delete headers["Content-Type"];
      const reqResult = await axios.get(
        this.apiPath(
          apiV2Route({
            preJurisdiction: ["immunizations"],
            jurisdiction: jurisdiction,
            postJurisdiction: [reportType.toString(), searchUid],
          })
        ),
        {
          headers: headers,
          responseType: "arraybuffer",
        }
      );
      result = reqResult.data;
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve your report right now. Please try again later."
      );
      throw err;
    }
  }

  async refreshRecords(): Promise<number> {
    await this.setRequestingUser();
    let result: number = -1;
    try {
      let response = await axios.get(
        this.apiPath(apiV2Route({ preJurisdiction: ["immunizations", "refresh_records_async"] })),
        this.#default_axios_options
      );
      result = response.data["estimated_wait_time"];
      return result;
    } catch (err) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't refresh your records at this time. Please try again later."
      );
    }
    return result;
  }

  async getVCICode(
    recordUid: string,
    contentType: VCIContentType,
    vaccineType: string,
    jurisdiction: string
  ): Promise<VCIResponse> {
    await this.setRequestingUser();
    // Copy headers to change the content type; don't change it globally for all future requests
    const headers = { ...this.#headers };
    headers["Content-Type"] = contentType;
    let result;
    try {
      // We MUST send data: null for the headers to be sent through! Why??? WHO KNOWS!
      // https://github.com/axios/axios/issues/86
      result = await axios.get(
        this.apiPath(
          apiV2Route({
            preJurisdiction: ["immunizations"],
            jurisdiction: jurisdiction,
            postJurisdiction: [
              "smartcard",
              "vaccine",
              recordUid,
              `${vaccineType}.smart-health-card`,
            ],
          })
        ),
        {
          headers: headers,
          data: null,
        }
      );
    } catch (err: any) {
      this.throwResponseError(
        err,
        "Sorry, Docket couldn't retrieve your Smart Health Card. Please try again later."
      );
    }

    if (result === undefined) {
      throw new Error("Unknown content type! Undefined data");
    }

    switch (contentType) {
      case VCIContentType.Default:
        return { verifiableCredential: result.data.verifiableCredential };
      case VCIContentType.SVG:
        return { svg: result.data };
      case VCIContentType.Numeric:
        return { shc: result.data };
      default:
        throw new Error("Unknown content type!");
    }
  }

  // === Provider configuration
  async getProviderConfigs(): Promise<ImmunizationProviderConfigResponse> {
    await this.setRequestingUser();
    let result: ImmunizationProviderConfigResponse = { configs: [] };
    try {
      result = (
        await axios.get(
          this.apiPath(
            apiV2Route({
              preJurisdiction: ["immunizations", "provider", "configs"],
            })
          ),
          this.#default_axios_options
        )
      ).data;
    } catch (err: any) {
      this.throwResponseError(
        err,
        "Docket encountered a problem retrieving important configuration. Please try again later."
      );
      throw err;
    }
    return result;
  }
}

/**
 * We implemented JWTs/bearer tokens in 2024. Previously two headers, X-User-Email and X-User-Token, were used
 * to authenticate calls.
 *
 * A bearer token has a lifetime, and when it expires it must be refreshed with a refresh token.
 *
 * This wrapper ensures that any call that ends in a `401 Unauthorized` response will attempt to refresh the access/refresh tokens
 * and retry the call.
 *
 * There's a way to do this with metaprogramming/prototypes but I dislike
 * that it obfuscates what's really happening. See
 * https://stackoverflow.com/questions/57738464/typescript-how-do-you-create-a-wrapper-class-that-has-all-the-methods-of-the-ba
 * I have a feeling Typescript would also throw a *fit* with that approach if we tried to make
 * TokenRefreshWrapper be an IDocketAPI.
 */
export class TokenRefreshWrapper implements IDocketAPI {
  readonly apiClient: IDocketAPI;
  protocol: string;
  hostname: string;
  #userRetrievalFn: () => Promise<UserAccount> | Promise<void>;

  constructor(apiClient: IDocketAPI) {
    this.apiClient = apiClient;
    this.protocol = apiClient.protocol;
    this.hostname = apiClient.hostname;
    this.#userRetrievalFn = () => Promise.resolve();
  }

  async automaticAuthRefresh<I extends any[], R>(
    requestFn: (...input: I) => Promise<R>,
    ...args: I
  ): Promise<R> {
    let err = null;
    try {
      return await requestFn.apply(this.apiClient, args);
    } catch (error: any) {
      err = error;
    }

    if (err?.cause?.response?.status === 401) {
      let tokens: Tokens;
      try {
        // This *must* re-save the user account
        tokens = await this.apiClient.refreshTokens();
      } catch (refreshError) {
        // Failed to refresh the token, log us out
        await logoutUser(this.apiClient);
        throw new DocketAPIError("Unauthorized: failed to re-authenticate");
      }

      if (tokens) {
        // re-try the call
        return await requestFn.apply(this.apiClient, args);
      }
    }
    // Re-throw
    throw err;
  }

  // Functions that don't need to check token validity / refresh if unauth'd
  setRequestingUserFn(userRetrievalFn: () => Promise<UserAccount>) {
    this.#userRetrievalFn = userRetrievalFn;
    return this.apiClient.setRequestingUserFn(userRetrievalFn);
  }
  createUser(userInfo: SignupRequest): Promise<UserAccount> {
    return this.apiClient.createUser(userInfo);
  }
  login(loginInfo: Login): Promise<UserAccount> {
    return this.apiClient.login(loginInfo);
  }
  sendSignInLink(email: string): Promise<void> {
    return this.apiClient.sendSignInLink(email);
  }
  loginWithFirebase(token: string): Promise<UserAccount> {
    return this.apiClient.loginWithFirebase(token);
  }
  initiateOidcAuth(stateIdentifier: string): Promise<void> {
    return this.apiClient.initiateOidcAuth(stateIdentifier);
  }
  loginWithAuthCode(code: string, stateIdentifier: string): Promise<UserAccount> {
    return this.apiClient.loginWithAuthCode(code, stateIdentifier);
  }
  // Functions that do need token validity
  getIzSearches(iconTapped: boolean): Promise<ImmunizationSearchAPI> {
    return this.apiClient.getIzSearches(iconTapped);
  }
  refreshTokens(): Promise<Tokens> {
    return this.apiClient.refreshTokens();
  }
  getUser(): Promise<UserAccount> {
    return this.automaticAuthRefresh(this.apiClient.getUser);
  }
  deleteUser(): Promise<Status> {
    return this.automaticAuthRefresh(this.apiClient.deleteUser);
  }
  updateUser(params: any): Promise<Status> {
    return this.automaticAuthRefresh(this.apiClient.updateUser, params);
  }
  deleteToken(): Promise<void> {
    return this.automaticAuthRefresh(this.apiClient.deleteToken);
  }
  addUserAccountPhone(phoneNumber: PhoneNumber): Promise<void> {
    return this.automaticAuthRefresh(this.apiClient.addUserAccountPhone, phoneNumber);
  }
  verifyUserAccountPhone(code: PhoneVerificationCode): Promise<void> {
    return this.automaticAuthRefresh(this.apiClient.verifyUserAccountPhone, code);
  }
  async izSearches(iconTapped: boolean): Promise<ImmunizationSearchAPI> {
    return this.automaticAuthRefresh(this.apiClient.getIzSearches, iconTapped);
  }
  enqueueIzSearch(
    request: ImmunizationSearchRequestAPI,
    jurisdiction: string
  ): Promise<ImmunizationEnqueuedResponseAPI> {
    return this.automaticAuthRefresh(this.apiClient.enqueueIzSearch, request, jurisdiction);
  }
  getIzSearch(searchUid: string, jurisdiction: string): Promise<void | ImmunizationSearchAPIData> {
    return this.automaticAuthRefresh(this.apiClient.getIzSearch, searchUid, jurisdiction);
  }
  deleteIzSearch(searchUid: string, jurisdiction: string): Promise<Status> {
    return this.automaticAuthRefresh(this.apiClient.deleteIzSearch, searchUid, jurisdiction);
  }
  sendImmunizationSearchPIN(
    searchUid: string,
    requestParameters: ImmunizationSearchPINRequest,
    jurisdiction: string
  ): Promise<Status> {
    return this.automaticAuthRefresh(
      this.apiClient.sendImmunizationSearchPIN,
      searchUid,
      requestParameters,
      jurisdiction
    );
  }
  verifyImmunizationSearchPIN(
    searchUid: string,
    pin: string,
    jurisdiction: string
  ): Promise<PINVerificationResponse> {
    return this.automaticAuthRefresh(
      this.apiClient.verifyImmunizationSearchPIN,
      searchUid,
      pin,
      jurisdiction
    );
  }
  verifyImmunizationProviderPIN(
    searchUid: string,
    pin: string,
    patientId: string,
    jurisdiction: string
  ): Promise<PINVerificationResponse> {
    return this.automaticAuthRefresh(
      this.apiClient.verifyImmunizationProviderPIN,
      searchUid,
      pin,
      patientId,
      jurisdiction
    );
  }
  getIzRecord(searchUid: string, jurisdiction: string): Promise<ImmunizationRecordAPIData> {
    return this.automaticAuthRefresh(this.apiClient.getIzRecord, searchUid, jurisdiction);
  }
  getIzScreeningRecord(searchUid: string, jurisdiction: string): Promise<ScreeningEvent[]> {
    return this.automaticAuthRefresh(this.apiClient.getIzScreeningRecord, searchUid, jurisdiction);
  }
  getAllIzRecords(): Promise<ImmunizationRecordsAPI> {
    return this.automaticAuthRefresh(this.apiClient.getAllIzRecords);
  }
  refreshRecords(): Promise<number> {
    return this.automaticAuthRefresh(this.apiClient.refreshRecords);
  }
  getRecordReport(
    searchUid: string,
    reportType: ReportType,
    jurisdiction: string
  ): Promise<ArrayBuffer> {
    return this.automaticAuthRefresh(
      this.apiClient.getRecordReport,
      searchUid,
      reportType,
      jurisdiction
    );
  }
  getVCICode(
    recordUid: string,
    contentType: VCIContentType,
    vaccineType: string,
    jurisdiction: string
  ): Promise<VCIResponse> {
    return this.automaticAuthRefresh(
      this.apiClient.getVCICode,
      recordUid,
      contentType,
      vaccineType,
      jurisdiction
    );
  }
  getProviderConfigs(): Promise<ImmunizationProviderConfigResponse> {
    return this.automaticAuthRefresh(this.apiClient.getProviderConfigs);
  }
}

async function persistUserToStorage(user: UserAccount): Promise<void> {
  await db().setItem(Key.UserAccount, user);
}

async function logoutUser(client: IDocketAPI): Promise<void> {
  await logout(client);
  window.location.href = "/";
}

let docketCareAPIV1: IDocketAPI | null = null;

/**
 * Get the default API client that is preconfigured to work.
 * @return the default client
 */
export function getAPIClient(): IDocketAPI {
  if (!docketCareAPIV1) {
    docketCareAPIV1 = new DocketCareAPIV1(API_DOMAIN, PROTOCOL);
  }
  docketCareAPIV1.setRequestingUserFn(async () => await db().getItem<UserAccount>(Key.UserAccount));
  return docketCareAPIV1;
}
