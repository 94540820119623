// Service Worker Registration
// Includes listeners for communication between the Main Thread and Service Worker Thread,
// via the Messaging API.
// This will help handle Deferred Action of SW updates, and UI displays denoting offline-mode.

/*
  If user elects to Update, then page is refreshed anyways and we don't need to handle
  a state change (due to the controllerchange listener above).
  If user elects to NOT update, we'll close the banner, but we also need additional
  logic to store in LocalForage that an update is pending.
  We can :
  - automatically update state after N seconds (meh)
  - wait for "navigations" and automatically update upon navigation (safer)
  - requery to the user that an update is pending after N seconds (potentially safest?)
*/

const toggleUpdateBanner = (open: boolean): void => {
  const el = document.getElementById("sw-toast");
  if (el) {
    const isOpen = el.classList.contains("active");
    if (isOpen) {
      if (!open) {
        el.classList.toggle("active");
      }
    } else {
      if (open) {
        el.classList.toggle("active");
      }
    }
  }
};

const registrationMessageListener = (): void => {
  navigator.serviceWorker.addEventListener("message", (event) => {
    switch (event.data.text) {
      case "CLOSE_BANNER":
        toggleUpdateBanner(false);
        break;
      case "OPEN_BANNER":
        // Really, this will never get called.
        toggleUpdateBanner(true);
        break;
    }
  });
};

const registrationDetectOffline = (): void => {
  const el = document.getElementById("sw-toast-offline");
  if (el) {
    el.innerText = "Offline, requests are all served through cache";
    if (el.classList.contains("active")) {
      el.classList.toggle("active");
      setTimeout(() => {
        el.classList.toggle("active");
      }, 1000);
    } else {
      el.classList.toggle("active");
    }
  }
};
const registrationDetectOnline = (): void => {
  const el = document.getElementById("sw-toast-offline");
  if (el) {
    el.innerText = "Back Online!";
    if (el.classList.contains("active")) {
      el.classList.toggle("active");
      setTimeout(() => {
        el.classList.toggle("active");
      }, 1000);
    } else {
      el.classList.toggle("active");
    }
  }
};

const registerValidSW = (): void => {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      registration.onupdatefound = (): void => {
        console.log("Update found");
        const installingWorker = registration.installing as ServiceWorker;
        if (installingWorker) {
          installingWorker.onstatechange = (): void => {
            switch (installingWorker.state) {
              case "installed":
                if (navigator.serviceWorker.controller) {
                  console.log("controller found, going through deferred action workflow");
                  toggleUpdateBanner(true);
                } else {
                  console.log("No current controller, sw is initialized for the first time");
                }
                break;
              case "redundant":
                console.log("The installing service worker became redundant");
                break;
            }
          };
        }
      };
    })
    .catch((error) => {
      console.log(`Service Worker Registration Failed with ${error}`);
    });
  navigator.serviceWorker.onmessage = (event): void => {
    console.log(event.data);
    /**
     * Provide a mechanism for the service worker to request that the main thread reload's the current user.
     * The primary use case is for the service worker to force the web app to use the latest assets after the user
     * has opted to update the app.
     */
    if (event.data === "REFRESH_WINDOW") {
      window.location.reload();
    }
  };
};

const validateSameOriginPolicy = (): boolean => {
  // Ensures the same-origin policy.
  // This might happen if a CDN is used to serve assets;
  // see https://github.com/facebook/create-react-app/issues/2374

  const publicUrl = new URL(process.env.PUBLIC_URL || window.location.href);

  if (publicUrl.origin !== window.location.origin) {
    console.log("INVALID ORIGIN");
    return false;
  }
  console.log("VALID ORIGIN");
  return true;
};

export const register = (): void => {
  // all done on the main thread
  // since upon a user's first page visit, there won't be a SW,
  // wait until page load to register one.
  if ("serviceWorker" in navigator) {
    if (!validateSameOriginPolicy()) return;

    if (navigator.serviceWorker.controller) {
      console.log("This page is currently controlled by a service worker");
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          if (registration.waiting) {
            if (registration.waiting.state === "installed") {
              registration.waiting.postMessage("SKIP_WAITING");
              // toggleUpdateBanner(true);
            }
          }
        }
      });
    } else {
      console.log("This page is not currently controlled by a service worker");
    }

    // navigator.serviceWorker.ready.then((registration) => {
    //   // registration.update();
    //   console.log("READY");
    //   console.log(registration);
    // });

    window.addEventListener("load", () => {
      registrationMessageListener();
      registerValidSW();
    });
    window.addEventListener("offline", registrationDetectOffline);
    window.addEventListener("online", registrationDetectOnline);
  }
};

export const unregister = (): void => {
  if ("serviceWorker" in navigator) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
};
