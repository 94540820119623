import React from "react";
import { useTranslation } from "react-i18next";
import "./disclaimerCard.css";
import { Modal } from "../modals/Modal";

interface DisclaimerModalProps {
  closeModal: () => void;
  stateCode: string;
}

export function DisclaimerStateModal({ closeModal, stateCode }: DisclaimerModalProps) {
  const { t } = useTranslation();

  const disclaimerTranslations = {
    consentTitle: t("immunizations.disclaimer_title"),
    recordsDisclaimer: t("immunizations.records_disclaimer", { context: stateCode }),
  };

  return (
    <Modal
      onCloseModal={closeModal}
      hasClose={true}
      title={disclaimerTranslations.consentTitle}
      confirm={t("generic.ok")}
      onConfirm={() => closeModal()}
    >
      <p>{disclaimerTranslations.recordsDisclaimer}</p>
    </Modal>
  );
}
