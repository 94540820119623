import { ImmunizationPatient } from "../../models/Interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../components/patientCard.css";
import ReportCard from "../../components/ReportCard";
import { getReportForState } from "../../utils/reportUtils";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import getIzProvider from "../../models/providers/IzProviders";

interface ReportListProps {
  patient: ImmunizationPatient;
}

export default function ReportList() {
  const { state } = useLocation();
  const { patient } = (state as ReportListProps) || {};
  const { t } = useTranslation();
  const providerKey: string = patient?.izProviderKey?.toLowerCase() || "";

  const njFeatures = useAppSelector((store) => store.immunizations.njFeatureFlag);

  const izProvider = getIzProvider(providerKey, t);
  const reportTitles = izProvider.reportTitles;

  const noPatient = () => {
    // TODO: I18N
    return (
      <li className="mb-4" key="0">
        Sorry, no reports are available. Please refresh or go back to your records.
      </li>
    );
  };

  const reports = () => {
    // For New Jersey, handle the feature flag
    const finalReportTitles =
      providerKey === "nj" && !njFeatures ? reportTitles.slice(0, 1) : reportTitles;
    return finalReportTitles.map((title, index) => (
      <li className="mb-4" key={index}>
        <ReportCard
          reportType={getReportForState(providerKey, index)}
          reportTitle={title}
          patient={patient}
        />
      </li>
    ));
  };

  return (
    <div className="section ml-1 mr-1 mt-1">
      <ul>{patient ? reports() : noPatient()}</ul>
    </div>
  );
}
