import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { errorLog, infoLog } from "../../utils/logger";
import SearchCard from "../../components/search/SearchCard";

import { useTranslation } from "react-i18next";
import { ImmunizationSearch, SearchStatus } from "../../models/Interfaces";
import { useNavigate } from "react-router";
import {
  deleteSearch,
  setPatients,
  setReports,
  setSearch,
  setSearches,
} from "../../redux/immunizationsSlice";
import {
  androidImmunizationsMultiMatchPdfURL,
  androidImmunizationsNoMatchPdfURL,
} from "../../utils/data";
import { DocketAPIError, getAPIClient, ImmunizationSearchAPI } from "../../apiClient";
import { GetPatientRecords, getPatientRecords } from "../../hooks/getSearches";
import { createSearches } from "../../hooks/getSearchesUtil";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { Modal } from "../../components/modals/Modal";
import "./searchList.css";

/**
 * Calculate when we should next poll for updates.
 *
 * @param timesPolled The number of times we've already polled for data
 * @returns 0 to the maximum poll time in milliseconds, or -1 if we should stop.
 */
export function getNextPollTime(timesPolled: number): number {
  const ONE_SECOND_IN_MS = 1000;
  const minDurationMs = ONE_SECOND_IN_MS;
  const minDurationPolls = 5; // We want to attempt to do some fast polling since in MOST cases the search comes back in < 5 secs
  const maxDurationMs = 60 * 60 * ONE_SECOND_IN_MS; // One poll every hour
  // This is absurdly high on purpose. It's just to prevent idle clients from infinitely driving traffic to us.
  // It takes 12 polls (17 including the min duration polls) to hit 1 hour polling time.
  // The maximum time a message can exist in the backend SQS queue is 14 days, or 336 hours.
  // 17 + 336 = 353.
  const maxPolls = 353;

  // We poll the backend as long as we're waiting on at least 1 search or record request from the backend.
  if (timesPolled < maxPolls) {
    if (timesPolled <= minDurationPolls) {
      // We're within our 'fast polling' window so throw out the previous calculation.
      return minDurationMs;
    }

    // We want an exponential decay with full jitter.
    // Canonical article from mbrooker: https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
    const expectedNextRequestTime = Math.min(
      maxDurationMs,
      minDurationMs * Math.pow(2, timesPolled - minDurationPolls)
    );
    // Add in a full jitter
    return Math.round(Math.random() * expectedNextRequestTime);
  }
  return -1;
}

export function SearchList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showNoMatchModal, setShowNoMatchModal] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null as unknown as ImmunizationSearch);
  const [showErrorModal, setShowErrorModal] = useState<string | boolean>();
  const [timesPolled, setTimesPolled] = useState(1);
  const [refreshPollingTimes, setRefreshPollingTimes] = useState(0);
  // searches selector to update UI
  const allSearches = useAppSelector((store) => store.immunizations.searches);
  const allPatients = useAppSelector((store) => store.immunizations.patients);
  const searchesInQueue = useAppSelector((store) => store.immunizations.searchQueue);
  const recordsInQueue = useAppSelector((store) => store.immunizations.recordsQueue);

  const timeoutFunc = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // We poll as long as we're waiting on at least 1 search or record request from the backend.

    // This effect gets re-invoked when `timesPolled` increments, which happens through
    // `updateSearches`. This is how `updateSearches` is effectively re-calling itself.
    infoLog(`Searches or records in queue? ${searchesOrRecordsInQueue()}`);

    if (searchesOrRecordsInQueue() && timesPolled < 11) {
      clearTimeout(timeoutFunc.current || undefined);
      timeoutFunc.current = setTimeout(updateSearches, getNextPollTime(timesPolled));
    } else {
      clearTimeout(timeoutFunc.current || undefined);
      timeoutFunc.current = null;
      setTimesPolled(1);
    }

    // Cleanup function
    return () => {
      if (timeoutFunc.current) {
        clearTimeout(timeoutFunc.current);
      }
    };
  }, [searchesInQueue, recordsInQueue, timesPolled]);

  const updateSearches = async function () {
    try {
      await getSearches();
      setTimesPolled(timesPolled + 1);
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorModal(e.message);
      } else {
        errorLog(e as Error);
      }
    }
  };

  const getSearches = async () => {
    const apiSearches: ImmunizationSearchAPI | null = await getAPIClient().getIzSearches(false);
    if (!apiSearches || !apiSearches?.data) {
      return;
    }

    // transform API results to typed searches
    const searches: ImmunizationSearch[] = createSearches(apiSearches.data);

    dispatch(setSearches(searches));
    // set verified patients and get records
    const results: GetPatientRecords = await getPatientRecords(searches, dispatch);

    infoLog(`SearchList: Got patients: ${JSON.stringify(results?.patients)}`);

    dispatch(setPatients(results?.patients));
    dispatch(setReports(results?.reports!));
  };

  const searchesOrRecordsInQueue = () => {
    return (
      (searchesInQueue && searchesInQueue.length > 0) ||
      (recordsInQueue && recordsInQueue.length > 0)
    );
  };

  function getSearchHeader() {
    return (
      <div className="level pt-6 has-background-transparent ml-5 mr-5">
        <div className="level-left ml-4 has-text-dark has-text-left">
          <h2>{`${t("immunizations.search_header_pt1")} ${allSearches.length} ${t(
            "immunizations.search_header_pt2"
          )}`}</h2>
        </div>
        <div className="level-right has-text-dark has-text-right">
          <button
            data-testid="newSearchBtn"
            className="button docket-button pl-5 pr-5 pt-2 pb-2 is-flex-mobile search-button mr-5"
            onClick={() => {
              dispatch(setSearch(null as unknown as ImmunizationSearch));
              navigate("newsearch");
            }}
          >
            {t("immunizations.search_start_new_search")}
          </button>
        </div>
      </div>
    );
  }

  const selectItemHandler = async (searchObj: ImmunizationSearch) => {
    setSelectedSearch(searchObj);
    dispatch(setSearch(searchObj));
    if (searchObj.status === SearchStatus.inQueue) {
      // await pullToRefresh()
    } else if (
      searchObj.status === SearchStatus.noRecords ||
      searchObj.status === SearchStatus.multiMatch
    ) {
      setShowNoMatchModal(true);
    } else if (
      searchObj.status === SearchStatus.partialMatchAltContacts &&
      !searchObj.dateVerified
    ) {
      navigate("altcontacts", { state: { fromSearchList: true } });
    } else if (
      // results other than 0, like ones above, either
      // need to be result 2 or need dateVerified
      searchObj.status !== SearchStatus.noMatch &&
      searchObj.status !== SearchStatus.multiMatch &&
      searchObj.status !== SearchStatus.basicMatchNoContacts &&
      searchObj.dateVerified
    ) {
      setShowMatchModal(true);
    } else {
      setShowNoMatchModal(true);
    }
  };

  const getNoMatchModal = () => {
    let title = "";
    let message: string = t("immunizations.no_match_multi_match_message");
    if (selectedSearch?.status === SearchStatus.basicMatchNoContacts) {
      title = t("immunizations.search_action_required");
      message = t("immunizations.search_action_required_message");
    } else if (selectedSearch?.status === SearchStatus.noMatch) {
      title = t("immunizations.no_match_alert_title");
    } else {
      title = t("immunizations.no_match_multi_match_alert_title");
    }

    return (
      showNoMatchModal && (
        <Modal
          hasClose={true}
          title={title}
          onCloseModal={() => setShowNoMatchModal(false)}
          actionButtons={
            <>
              <button
                className="button docket-button secondary pl-4 pr-4"
                aria-label="Review search"
                onClick={() => navFromNoMatchModal(true)}
              >
                {t("immunizations.no_match_multi_match_review_button")}
              </button>
              <button
                className="button docket-button pl-4 pr-4"
                aria-label="More info"
                onClick={() => navFromNoMatchModal(false)}
              >
                {t("immunizations.no_match_multi_match_no_match_button")}
              </button>
            </>
          }
        >
          <p>{message}</p>
        </Modal>
      )
    );
  };

  const navFromNoMatchModal = (review: boolean) => {
    if (review) {
      navigate("newsearch");
    } else {
      navigate("results", { state: { isScenarioB: false } });
    }
    setShowNoMatchModal(false);
  };

  const getSearchNavModal = () => {
    return (
      showMatchModal && (
        <Modal
          hasClose={true}
          title={t("immunizations.records_found_alert_header")}
          onCloseModal={() => setShowMatchModal(false)}
          actionButtons={
            <>
              <button
                className="button docket-button secondary pl-4 pr-4"
                aria-label="Edit search"
                onClick={() => navFromMatchModal(false)}
              >
                {t("immunizations.records_found_edit_search")}
              </button>
              <button
                className="button docket-button pl-4 pr-4"
                aria-label="View records"
                onClick={() => navFromMatchModal(true)}
              >
                {t("immunizations.records_found_view_records")}
              </button>
            </>
          }
        >
          <p>{t("immunizations.records_found_alert_message")}</p>
        </Modal>
      )
    );
  };

  const navFromMatchModal = (viewRecords: boolean) => {
    const patient = allPatients.find((p) => p.searchUid === selectedSearch?.uid);
    //infoLog(`patient index ${allPatients.indexOf(patient!)}`);
    if (viewRecords && patient) {
      if (patient.records && patient.records.length > 0) {
        navigate("/home/records#" + allPatients.indexOf(patient));
      } else {
        navigate("/home/records");
      }
    } else {
      if (searchesInQueue.length > 0) {
        const [title, message] = t("immunizations.search_check_search_in_queue").split("|");
        setShowErrorModal(message);
      } else {
        navigate("newsearch");
      }
    }
    setShowMatchModal(false);
  };

  return (
    <>
      {getSearchNavModal()}
      {getNoMatchModal()}
      {getSearchHeader()}
      {showErrorModal && (
        <ErrorModal onCloseModal={() => setShowErrorModal(false)}>
          <p>{showErrorModal}</p>
        </ErrorModal>
      )}
      <div className="section">
        {allSearches.length === 0 ? (
          <div className="has-text-centered">
            <br />
            <h2 className="is-size-5 has-text-weight-bold has-text-black">
              {t("immunizations.search_records_message")}
            </h2>
            <br />
            <br />
            <button
              className="button docket-button pl-4 pr-4"
              onClick={() => {
                dispatch(setSearch(null as unknown as ImmunizationSearch));
                navigate("newsearch");
              }}
            >
              {t("immunizations.immunizations_add_records_button")}
            </button>
          </div>
        ) : (
          <ul>
            {allSearches.map((search, index) => (
              <li className="mb-4" key={index}>
                <SearchCard
                  search={search}
                  index={index}
                  onSelect={() => {
                    selectItemHandler(search);
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default SearchList;
