import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  DocketAPIAlreadyEnqueuedError,
  DocketAPIError,
  getAPIClient,
  ImmunizationSearchRequestAPI,
  LegalSex,
} from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { infoLog } from "../../utils/logger";
import {
  checkBoxValueMap,
  getLocalizedLegalSexChoices,
  getWhoAmIChoices,
  getWhoAmiRadios,
  getHorizontalRadioSection,
  SearchFormSchema,
  getWhoAmILegacy,
  getWhoAmIChildChoices,
} from "../../utils/formUtils";
import "./searchForm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SearchFormHeader from "../../components/search/SearchFormHeader";
import lodash from "lodash";
import { ImmunizationNewSearch, ImmunizationSearch, UserAccount } from "../../models/Interfaces";
import { readableDate } from "../../utils/data";
import { deleteSearch, setSearch, setSearchQueue } from "../../redux/immunizationsSlice";
import { searchBuilder } from "../../hooks/getSearchesUtil";
import db, { Key } from "../../database";
import {
  akSearchFooter,
  idSearchFooter,
  mnSearchFooter,
  njSearchFooter,
  utSearchFooter,
} from "../../components/LegalFooters";
import { Modal } from "../../components/modals/Modal";
import { ErrorModal } from "../../components/modals/ErrorModal";

export function SearchForm() {
  const navigate = useNavigate();
  // searches selector to update UI
  const existingSearch = useAppSelector((store) => store.immunizations.search);
  const providerLabels = useAppSelector((store) => store.immunizations.providerLabels);
  const providerConfigs = useAppSelector((store) => store.immunizations.providerConfigs);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // patient or parent DOB
  const [dob, setDob] = useState(
    existingSearch
      ? moment(existingSearch.dateOfBirth).toDate()
      : moment().subtract(18, "years").toDate()
  );

  // child DOB
  const [cDob, setCDob] = useState(
    existingSearch && existingSearch.childDateOfBirth
      ? moment(existingSearch.childDateOfBirth).toDate()
      : new Date()
  );
  // DOB checkbox, to map selection to values
  const [checkboxValue, setCheckboxValue] = useState(getLocalizedLegalSexChoices(t));
  // Child DOB checkbox, to map selection to values
  const [childCheckboxValue, setChildCheckboxValue] = useState(getLocalizedLegalSexChoices(t));
  // who am I checkbox, to map selection to values
  // ToDo: set initial state from prev search or user object
  const [whoAmIValue, setWhoAmIValue] = useState(getWhoAmIChoices(t));
  const [gaveConsent, setGaveConsent] = useState(false);
  const [showChildSearch, setShowChildSearch] = useState(
    existingSearch && existingSearch.childFirstName ? true : false
  );
  const [user, setUser] = useState(null as unknown as UserAccount);
  const [provider, setProvider] = useState("");
  const providerKey = useRef("");

  const checkboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(checkboxValue, index);
    setCheckboxValue(newValue);
  };
  // Child DOB checkbox, to map selection to values
  const childCheckboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(childCheckboxValue, index);
    setChildCheckboxValue(newValue);
  };
  // who am I checkbox, to map selection to values
  // ToDo: set initial state from prev search or user object
  const whoAmIHandler = (value: any, index: number) => {
    infoLog(`whoAmIHandler ${value} ${index}`);
    const newValue = checkBoxValueMap(whoAmIValue, index);
    setWhoAmIValue(newValue);
  };
  const handleConsentChange = (checked: boolean) => {
    setGaveConsent(checked);
  };

  type ErrorModalParams = {
    title: string;
    body: string;
    show: boolean;
  };
  const [showErrorModal, setShowErrorModal] = useState<ErrorModalParams>({
    title: "",
    body: "",
    show: false,
  });
  const [title, message] = t("immunizations.search_check_search_in_queue").split("|");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SearchFormSchema),
  });

  const searchFormTranslations = {
    firstName: t("immunizations.search_my_first_name_label"),
    lastName: t("immunizations.search_my_last_name_label"),
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
    childFirstName: t("immunizations.search_child_first_name_label"),
    childLastName: t("immunizations.search_child_last_name_label"),
    childDateOfBirth: t("immunizations.search_child_dob_label"),
    childLegalSex: t("immunizations.search_child_legal_sex_label"),
    consentTitle: t("immunizations.search_consent_title"),
    consentMessage: t("immunizations.search_consent_message"),
    consentCheckBoxLabel: t("immunizations.i_consent"),
  };

  useEffect(() => {
    if (!provider || provider.length === 0) {
      providerKey.current = "";
      return;
    }

    let destination_id = "";
    for (const value of Object.values(providerConfigs)) {
      if (value.enabled && provider.includes(value.iz_provider.name)) {
        destination_id = value.iz_provider.destination_id;
        break;
      }
    }
    infoLog(`provider '${destination_id}'`);
    if (destination_id.length > 0) {
      providerKey.current = destination_id;
    } else {
      providerKey.current = "";
    }
  }, [provider]);

  useEffect(() => {
    if (existingSearch) {
      infoLog(`existingSearch ${JSON.stringify(existingSearch)}`);
      const newValue = checkBoxValueMap(
        checkboxValue,
        lodash.findIndex(checkboxValue, function (item) {
          return item.value === existingSearch.legalSex;
        })
      );
      setCheckboxValue(newValue);
      if (existingSearch.childDateOfBirth) {
        const newChildValue = checkBoxValueMap(
          childCheckboxValue,
          lodash.findIndex(childCheckboxValue, function (item) {
            return item.value === existingSearch.childLegalSex;
          })
        );
        setChildCheckboxValue(newChildValue);
      }
      if (existingSearch.whoAmI && existingSearch.whoAmI !== " legacy") {
        const newWhoAmIValue = checkBoxValueMap(
          whoAmIValue,
          lodash.findIndex(whoAmIValue, function (item) {
            return item.value === existingSearch.whoAmI;
          })
        );
        setWhoAmIValue(existingSearch.whoAmI === " legacy" ? getWhoAmILegacy(t) : newWhoAmIValue);
      }
    } else if (!user) {
      // populate the form with user profile data
      db()
        .getItem(Key.UserAccount)
        .then((result) => {
          setUser(result as UserAccount);
          const newValue = checkBoxValueMap(
            checkboxValue,
            lodash.findIndex(checkboxValue, function (item) {
              return (
                item.value === (result as UserAccount).legal_sex ||
                item.value === (result as UserAccount).legal_sex?.charAt(0).toUpperCase()
              );
            })
          );
          setCheckboxValue(newValue);
          let defaultValues = { firstName: "", lastName: "" };
          defaultValues.firstName = (result as UserAccount).first_name ?? "";
          defaultValues.lastName = (result as UserAccount).last_name ?? "";
          // reset was the only way to get hook form to see the form values as valid
          reset({ ...defaultValues });
          if ((result as UserAccount).dob) {
            setDob(moment((result as UserAccount).dob).toDate());
          }
        });
    }
    return () => {};
  }, []);

  const onSubmit = async (data: any) => {
    infoLog(`onSubmit ${JSON.stringify(data)}`);
    // This will control the error modal as well, so we don't need to worry about it here.
    const dobsValid = await checkDates();
    //let checkDup = await checkForDuplicateSearch(data)
    const sex = lodash.find(checkboxValue, ["checked", true]);
    const csex = lodash.find(childCheckboxValue, ["checked", true]);
    const who = lodash.find(whoAmIValue, ["checked", true]);
    const legalSex = sex?.value ?? null;
    const cLegalSex = csex?.value ?? null;

    // Validate child search data first since it appears at the top of the form
    if (showChildSearch) {
      let errorMsg = "";
      if (!data.childFirstName) {
        errorMsg = t("immunizations.search_check_child_first_name");
      } else if (!data.childLastName) {
        errorMsg = t("immunizations.search_check_child_last_name");
      } else if (!cLegalSex) {
        errorMsg = t("immunizations.search_check_child_legal_sex");
      }

      if (errorMsg.length > 0) {
        setShowErrorModal({
          title: t("immunizations.search_check_header"),
          body: errorMsg,
          show: true,
        });
        return;
      }
    }

    let errorMsg = "";
    if (!data.firstName) {
      errorMsg = t("immunizations.search_check_first_name");
    } else if (!data.lastName) {
      errorMsg = t("immunizations.search_check_last_name");
    } else if (!legalSex) {
      errorMsg = t("immunizations.search_check_legal_sex");
    } else if (!showChildSearch && !who) {
      // Child searches can only be guardians, so we skip this check since it's auto-selected for the user.
      errorMsg = t("immunizations.search_check_who_am_i");
    } else if (!gaveConsent) {
      errorMsg = t("immunizations.search_check_consent");
    } else if (!providerKey.current || providerKey.current.length == 0) {
      errorMsg = t("immunizations.search_check_provider");
    }

    if (errorMsg.length > 0) {
      setShowErrorModal({
        title: t("immunizations.search_check_header"),
        body: errorMsg,
        show: true,
      });
      return;
    }

    if (dobsValid) {
      // infoLog(JSON.stringify(data));
      // await dispatch(immunizationActions.setSearch(null))
      await runSearch(data);
    }
  };

  const checkDates = async () => {
    let params: ErrorModalParams = {
      title: "",
      body: "",
      show: false,
    };

    // no child searches if the child is over 18...
    if (showChildSearch && moment().subtract(18, "years").isAfter(moment(cDob), "day")) {
      params = {
        title: t("immunizations.search_age_error_child_title"),
        body: t("immunizations.search_age_error_child_18"),
        show: true,
      };
    }
    // no search if the adult is under 18...
    else if (moment().subtract(18, "years").isBefore(moment(dob), "day")) {
      params = {
        title: t("immunizations.search_age_error_title"),
        body: t("immunizations.search_age_error_18"),
        show: true,
      };
    }

    if (params.show) {
      setShowErrorModal(params);
    }
    return !params.show;
  };

  const runSearch = async (data: ImmunizationNewSearch) => {
    let sex = lodash.find(checkboxValue, ["checked", true]);
    let whoami = !showChildSearch
      ? lodash.find(whoAmIValue, ["checked", true])
      : { value: "guardian" };
    let childLegalSex = showChildSearch
      ? lodash.find(childCheckboxValue, ["checked", true])
      : { value: "" };
    data.dateOfBirth = readableDate(dob);
    data.legalSex = sex ? sex.value : "U";
    data.childLegalSex = childLegalSex!.value;
    data.childDateOfBirth = showChildSearch ? readableDate(cDob) : "";
    data.childFirstName = data.childFirstName ? data.childFirstName.trim() : "";
    data.childLastName = data.childLastName ? data.childLastName.trim() : "";
    data.whoAmI = whoami!.value;
    // ToDo: set izProviderKey based on config or subdomain
    data.izProviderKey = providerKey.current;

    let searchObj: ImmunizationSearchRequestAPI = {
      first_name: data.firstName.trim(),
      last_name: data.lastName.trim(),
      dob: data.dateOfBirth,
      legal_sex: data.legalSex as LegalSex,
      child_first_name: data.childFirstName,
      child_last_name: data.childLastName,
      child_dob: data.childDateOfBirth,
      child_legal_sex: data.childLegalSex as LegalSex,
      who_am_i: data.whoAmI,
      iz_provider_key: data.izProviderKey,
    };

    //setIsSearchRunning(true)
    try {
      const response = await getAPIClient().enqueueIzSearch(searchObj, searchObj.iz_provider_key);
      // set the queue and check the search
      if (response) {
        dispatch(setSearchQueue([response]));
        const search = await getAPIClient().getIzSearch(response.uid, searchObj.iz_provider_key);
        let newSearch = null;
        // Search can be null if we don't yet have a result. It's still in-progress.
        // This happens when the backend gives us a 204.
        // Any exceptions should bubble out to the catch().

        if (search) {
          newSearch = searchBuilder(search);
          infoLog(`newSearch created ${JSON.stringify(newSearch)}`);
          dispatch(setSearch(newSearch));
        }
        navigate("../results", { state: { isScenarioB: false } });
      }
    } catch (e: unknown) {
      if (e instanceof DocketAPIAlreadyEnqueuedError) {
        setShowErrorModal({
          title: t("immunizations.search_check_search_in_queue").split("|")[0],
          body: t("immunizations.search_check_search_in_queue").split("|")[1],
          show: true,
        });
      } else if (e instanceof DocketAPIError) {
        setShowErrorModal({
          title: t("generic.error"),
          body: e.message,
          show: true,
        });
      }
    }
  };

  const runDeleteSearch = async () => {
    try {
      infoLog(`delete search w uid ${existingSearch.uid}`);
      await getAPIClient().deleteIzSearch(existingSearch.uid, existingSearch.izProviderKey);
      dispatch(deleteSearch(existingSearch));
      navigate(-1);
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorModal({
          title: t("immunizations.search_check_search_not_deleted"),
          body: e.message,
          show: true,
        });
      }
    }
  };

  const getInputField = (
    yupField: "firstName" | "lastName" | "childFirstName" | "childLastName",
    displayName: string,
    defaultVal: string = ""
  ) => {
    return (
      <div key={yupField}>
        <div className="field">
          <label className="label form-input-label">
            {displayName} <span className="required-tag"> ({t("system.required")})</span>
          </label>
          <div className="control has-background-white">
            <input
              className="input has-text-dark"
              type="text"
              placeholder={displayName}
              defaultValue={defaultVal}
              {...register(yupField)}
            />
          </div>
        </div>
        <p className="label is-size-6">{errors[yupField] && t("system.required_error")}</p>
      </div>
    );
  };

  const getParentForm = () => {
    return (
      <>
        {showChildSearch && (
          <h1 className="is-text-dark is-size-4 mb-4">
            {t("immunizations.search_parent_guardian_header")}
          </h1>
        )}
        <div className="columns">
          <div className="column is-third">
            {getInputField(
              "firstName",
              searchFormTranslations.firstName,
              existingSearch?.firstName
                ? existingSearch?.firstName
                : user?.first_name
                ? user.first_name
                : ""
            )}
          </div>
          <div className="column is-third">
            {getInputField(
              "lastName",
              searchFormTranslations.lastName,
              existingSearch?.lastName
                ? existingSearch?.lastName
                : user?.last_name
                ? user.last_name
                : ""
            )}
          </div>
          <div className="column is-third">
            <p className="label form-input-label">
              {searchFormTranslations.dateOfBirth}{" "}
              <span className="required-tag"> ({t("system.required")})</span>
            </p>
            <DatePicker
              key={"dateOfBirth"}
              customInput={
                <input className="input has-dark-text" id="dateOfBirth" placeholder=""></input>
              }
              selected={dob}
              inline={false}
              onChange={(date) => setDob(date!)}
            />
          </div>
        </div>
        <div>
          {getHorizontalRadioSection(
            checkboxValue,
            searchFormTranslations.legalSex,
            checkboxHandler,
            "checkbox",
            "gender",
            t
          )}
          <br />
        </div>
      </>
    );
  };

  const getChildForm = () => {
    return (
      <>
        <h1 className="is-text-dark is-size-4 mb-4">{t("immunizations.search_child_header")}</h1>
        <div className="columns">
          <div className="column is-third">
            {getInputField(
              "childFirstName",
              searchFormTranslations.childFirstName,
              existingSearch?.childFirstName ? existingSearch?.childFirstName : ""
            )}
          </div>
          <div className="column is-third">
            {getInputField(
              "childLastName",
              searchFormTranslations.childLastName,
              existingSearch?.childLastName ? existingSearch?.childLastName : ""
            )}
          </div>
          <div className="column is-third">
            <p className="label form-input-label">
              {searchFormTranslations.childDateOfBirth}{" "}
              <span className="required-tag"> ({t("system.required")})</span>
            </p>

            <DatePicker
              key={"childDateOfBirth"}
              customInput={
                <input className="input has-dark-text" id="dateOfBirth" placeholder=""></input>
              }
              selected={cDob}
              inline={false}
              onChange={(date) => setCDob(date!)}
            />
          </div>
        </div>
        <div>
          {getHorizontalRadioSection(
            childCheckboxValue,
            searchFormTranslations.childLegalSex,
            childCheckboxHandler,
            "checkbox",
            "childGender",
            t
          )}
          <br />

          <hr />
          <br />
        </div>
      </>
    );
  };

  const getConsentForm = () => {
    return (
      <div key={"consent"}>
        <br />
        <p className="label form-input-label mt-5">{searchFormTranslations.consentTitle}</p>
        <br />
        <p className="mb-5 has-text-dark">{searchFormTranslations.consentMessage}</p>
        <label className="checkbox mb-5 has-text-dark">
          <input
            type="checkbox"
            name={searchFormTranslations.consentCheckBoxLabel}
            checked={gaveConsent}
            onChange={(e) => handleConsentChange(e.target.checked)}
          />
          &nbsp;&nbsp;{searchFormTranslations.consentCheckBoxLabel}
        </label>
      </div>
    );
  };

  return (
    <div className="mb-6">
      {showErrorModal.show && (
        <ErrorModal
          title={showErrorModal.title}
          onCloseModal={() =>
            setShowErrorModal((cur: ErrorModalParams): ErrorModalParams => {
              return { title: cur.title, body: cur.body, show: false };
            })
          }
        >
          <p>{showErrorModal.body}</p>
        </ErrorModal>
      )}
      <SearchFormHeader
        deleteSearch={existingSearch ? runDeleteSearch : () => {}}
        showChildSearch={showChildSearch}
        showChild={setShowChildSearch}
        showDelete={!!existingSearch}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns">
          <div className="column is-0" />
          <div className="column has-text-dark">
            <div className="form-container has-dark-text">
              {showChildSearch && getChildForm()}
              {getParentForm()}
              {showChildSearch
                ? getWhoAmiRadios(
                    getWhoAmIChildChoices(t),
                    t("immunizations.i_am"),
                    whoAmIHandler,
                    t
                  )
                : getWhoAmiRadios(whoAmIValue, t("immunizations.i_am"), whoAmIHandler, t)}
              <hr />
              {getConsentForm()}
              <p className="label form-input-label mt-5">
                {t("immunizations.search_state_registry_label")}
              </p>
              <div className="select mb-5">
                <select onChange={(e) => setProvider(e.target.value)}>
                  {providerLabels.map((label) => (
                    <option key={label}>{label}</option>
                  ))}
                </select>
              </div>
              <br />
              <button
                type="submit"
                data-testid="search-form-button"
                className="button mt-5 docket-button pl-5 pr-5 is-pulled-right mb-5"
              >
                {t("immunizations.search_records_button")}
              </button>

              <br />
              <br />

              <br />
              <br />
              {
                // TODO: Move these into the IzProvider
              }
              {provider.includes("Alaska") && akSearchFooter(t)}
              {provider.includes("Idaho") && idSearchFooter(t)}
              {provider.includes("Minn") && mnSearchFooter(t)}
              {provider.includes("New Jersey") && njSearchFooter(t)}
              {provider.includes("Utah") && utSearchFooter(t)}
            </div>
          </div>
          <div className="column is-0" />
        </div>
      </form>
    </div>
  );
}

export default SearchForm;
