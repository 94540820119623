import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./disclaimerCard.css";
import getIzProvider from "../../models/providers/IzProviders";
import { Modal } from "../modals/Modal";

interface ConsentFormProps {
  handleConsentChange: (isChecked: boolean) => void;
  email: string;
  closeModal: () => void;
  gaveConsent: boolean;
}

export default function ConsentForm({
  handleConsentChange,
  email,
  closeModal,
  gaveConsent,
}: ConsentFormProps) {
  const { t } = useTranslation();

  const disclosureTranslations = {
    consentTitle: t("immunizations.search_consent_title"),
    prominantDisclosure: t("immunizations.prominant_disclosure"),
    consentCheckBoxLabel: t("immunizations.i_consent"),
  };

  return (
    <Modal
      title={disclosureTranslations.consentTitle}
      hasClose={false}
      data-testid="consent-modal"
      actionButtons={
        <button
          className={"button docket-button pl-4 pr-4"}
          onClick={closeModal}
          disabled={!gaveConsent}
        >
          {disclosureTranslations.consentTitle.toUpperCase()}
        </button>
      }
      onCloseModal={closeModal}
    >
      <p className="mb-5">{disclosureTranslations.prominantDisclosure}</p>
      <label className="checkbox">
        <input
          type="checkbox"
          name={disclosureTranslations.consentCheckBoxLabel}
          checked={gaveConsent}
          onChange={(e) => handleConsentChange(e.target.checked)}
        />
        &nbsp;&nbsp;{disclosureTranslations.consentCheckBoxLabel}
      </label>
    </Modal>
  );
}
