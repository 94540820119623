import { setAPIConfig } from "../../redux/immunizationsSlice";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { UserAccount } from "../../models/Interfaces";
import db, { Key } from "../../database";
import { getAPIClient } from "../../apiClient";
import { infoLog } from "../../utils/logger";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./navbar.css";
import React from "react";
import { STAY_LOGGED_IN_PW, SCREENINGS_ENABLED } from "../../globals";
import { useBranding } from "../../branding/useBranding";
import { getAuth } from "firebase/auth";
import logout from "../../utils/logout";

export function Navbar() {
  const [stayLogged, setStayLogged] = useState<boolean | null>(null);
  const allPatients = useAppSelector((store) => store.immunizations.patients);
  const hasScreenings =
    SCREENINGS_ENABLED &&
    allPatients.some((patient) => patient.screenings && patient.screenings.length > 0);

  const branding = useBranding();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [menuIsActive, setMenuIsActive] = useState(false);

  const [navBarUserName, setNavBarUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const navbarMenuRef = React.useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    setMenuIsActive((current) => !current);
  };

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      try {
        await db().unlock(STAY_LOGGED_IN_PW);
        setStayLogged(true);
      } catch (e: unknown) {
        setStayLogged(false);
      }
    };

    checkLoggedInStatus();
  }, []);

  useEffect(() => {
    const closeMenu = (event: MouseEvent) => {
      if (
        menuIsActive &&
        navbarMenuRef.current &&
        !navbarMenuRef.current.contains(event.target as Node)
      ) {
        setMenuIsActive(false);
      }
    };

    document.body.addEventListener("mousedown", closeMenu);

    return () => {
      document.body.removeEventListener("mousedown", closeMenu);
    };
  }, [menuIsActive]);

  const handleClick = () => {
    setMenuIsActive(false);
  };

  async function signOut() {
    await logout(getAPIClient(), getAuth());
    navigate("/");
  }

  const providerConfigs = useAppSelector((store) => store.immunizations.providerConfigs);

  const handleProviders = useCallback(() => {
    if (providerConfigs.length === 0) {
      getAPIClient()
        .getProviderConfigs()
        .then((response) => {
          if (response?.configs) {
            infoLog(`getProviderConfig:: ${JSON.stringify(response.configs)}`);
            dispatch(setAPIConfig(response.configs));
          }
        });
    }
  }, [providerConfigs]);

  useEffect(() => {
    if (navBarUserName.length === 0) {
      db()
        .getItem(Key.UserAccount)
        .then((result) => {
          const user = result as UserAccount;
          //infoLog(`user ${user.email}`);
          handleProviders();
          setEmail(user?.email ?? "");
          setNavBarUserName(user?.first_name + " " + user?.last_name ?? "");
        });
    }
  }, [navBarUserName]);

  return (
    <nav
      className="navbar docket-navbar"
      role="navigation"
      aria-label="main navigation"
      data-testid="navbar"
    >
      <div className="navbar-brand has-text-white">
        <a className="navbar-item ml-5" onClick={() => navigate("/home/search")}>
          <img src={`/${branding.headerImage}`} height="28" alt="Docket logo" />
          {/*<img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" />*/}
        </a>

        {stayLogged === false && (
          <div className="ml-2 is-flex is-align-items-center">
            <p className="is-size-7-mobile">
              <b>{t("generic.warning")}:</b> {t("menu.log_out_warning")}
            </p>
          </div>
        )}

        <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          data-target="navbarBasicExample"
          aria-expanded={menuIsActive}
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${menuIsActive ? "is-active" : ""} navbar-absolute`}
        ref={navbarMenuRef}
      >
        <div className="navbar-end">
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">{t("menu.immunizations")}</a>

            <div className="navbar-dropdown">
              <Link to="/home/search" className="navbar-item" onClick={handleClick}>
                {t("immunizations.tab_bar_search_title")}
              </Link>
              <Link className="navbar-item" to="/home/records" onClick={handleClick}>
                {t("immunizations.tab_bar_records_title")}
              </Link>
            </div>
          </div>

          {hasScreenings && (
            <>
              <div className="vl is-hidden-touch"></div>
              <Link className="navbar-item" to="/home/records/screening" onClick={handleClick}>
                {t("screening.tab_bar_screenings_title")}
              </Link>
            </>
          )}
          <div className="vl is-hidden-touch"></div>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              <div className="is-flex is-flex-direction-column">
                <p>{`${navBarUserName}`}</p>
                <div className="is-size-7">{`${email}`}</div>
              </div>
            </a>

            <div className="navbar-dropdown">
              <Link to="/home/settings" className="navbar-item" onClick={handleClick}>
                {t("menu.settings")}
              </Link>
              <a className="navbar-item" onClick={() => signOut().then(() => {})}>
                {t("menu.sign_out")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
