import React, { useState, useEffect } from "react";
import { Modal } from "../modals/Modal";
import { reset as resetLoginSlice } from "../../redux/loginSlice";
import { reset as resetImmunizationsSlice, setAPIConfig } from "../../redux/immunizationsSlice";
import { useNavigate } from "react-router";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import db, { Key } from "../../database";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";
import { getAPIClient } from "../../apiClient";
import logout from "../../utils/logout";
import { getAuth } from "firebase/auth";

function Timer({ initialMinute = 1, initialSeconds = 0 }) {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const navigate = useNavigate();

  const { reset } = useIdleTimer({});

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          logout(getAPIClient(), getAuth()).then(() => {
            navigate("/");
          });
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const { t } = useTranslation();

  return (
    <Modal
      hasClose={false}
      onCloseModal={() => reset()}
      title={t("system.inactivity_header")}
      confirm={t("generic.continue")}
    >
      <div>
        {minutes === 0 && seconds === 0 ? null : (
          <>
            <p>
              {t("system.inactivity_warning")}{" "}
              <h1 className="is-size-3">
                {minutes} {t("system.minutes", { count: minutes })}{" "}
                {seconds < 10 ? `0${seconds}` : seconds} {t("system.seconds", { count: seconds })}
              </h1>
              <br />
              {t("system.inactivity_continue")}
            </p>
          </>
        )}
      </div>
    </Modal>
  );
}

export default Timer;
