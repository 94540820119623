import i18n, { TFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { WHITELABEL_KEY } from "./globals";
import HttpApi from "i18next-http-backend";
import Bugsnag from "@bugsnag/js";
import { warnLog } from "./utils/logger";

// Figure out what namespace we want to use.
// This allows us to do state-specific string overrides using all standard i18n behavior.
function getDefaultNS(): string {
  if (WHITELABEL_KEY === "default") {
    return "translation";
  }
  return `${WHITELABEL_KEY}_translation`;
}

export async function initializeI18next(): Promise<TFunction> {
  const missingKeyHandler = (
    lngs: readonly string[],
    ns: string,
    key: string,
    fallbackValue: string,
    updateMissing: boolean,
    options: any
  ) => {
    const msg = `Could not find symbol ${ns}:${key} for languages ${lngs.join(
      ","
    )}, fallback value '${fallbackValue}'`;
    warnLog(msg);
    Bugsnag.notify(msg);
  };

  return i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      // 🥴 The language detector will use navigator.language which looks correct to me, but it decides that
      // en-US is still the best option. Perhaps because it's preloaded? Let's make the language list explicit.
      supportedLngs: [
        "ar",
        "de",
        "en",
        "es-MX",
        "fa",
        "fr",
        "gu-IN",
        "hi",
        "ht",
        "it",
        "km",
        "ko",
        "pl",
        "pt-PT",
        "ru",
        "so",
        "tl",
        "uk",
        "ur-PK",
        "vi",
        "zh-CN",
        "zh-TW",
      ],
      fallbackLng: "en",
      fallbackNS: "translation", // this is the OG, no-namespace version of translations
      defaultNS: getDefaultNS(), // Ta-da, we can get state-specific "namespaces" this way that override default keys
      preload: ["en"],
      debug: true,
      react: {
        useSuspense: false,
      },
      saveMissing: true,
      missingKeyHandler: missingKeyHandler,
      backend: {
        // something like e.g. '/locales/en/translation.json', or '/locales/en/nj_translation.json'
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });
}

export default i18n;
