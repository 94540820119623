import React, { PropsWithChildren } from "react";
import "./modal.css";
import { useTranslation } from "react-i18next";

interface ModalProps {
  title?: string | React.ReactNode;
  confirm?: string;
  onCloseModal: () => void;
  hasClose: boolean;
  onConfirm?: () => void;
  actionButtons?: React.ReactNode;
}

export function Modal({
  title,
  children,
  confirm,
  onCloseModal,
  hasClose,
  actionButtons,
  onConfirm,
  ...rest
}: PropsWithChildren<ModalProps>) {
  const { t } = useTranslation();
  return (
    <div className="modal is-active docket-modal" {...rest}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <head className="modal-card-head">
          {title && (
            <p className="modal-card-title is-size-4 is-bold">
              <b>{title}</b>
            </p>
          )}
          {hasClose && (
            <button className="delete" aria-label="Close" onClick={onCloseModal}></button>
          )}
        </head>
        <section className="modal-card-body has-text-black">{children}</section>
        <footer className="modal-card-foot">
          {onConfirm && (
            <button className={"button docket-button pl-4 pr-4"} onClick={onConfirm}>
              {confirm || t("generic.ok")}
            </button>
          )}
          {actionButtons && actionButtons}
        </footer>
      </div>
    </div>
  );
}
