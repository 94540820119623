import { useState, useEffect } from "react";
import { DocketAPIError, getAPIClient, ImmunizationSearchAPI } from "../apiClient";
import { useAppDispatch } from "../hooks";
import { createSearches, setDefaultReports, createNewRecord } from "./getSearchesUtil";
import {
  setReports,
  setPatients,
  setSearches,
  setRecordsQueue,
  removeFromRecordsQueue,
} from "../redux/immunizationsSlice";
import { infoLog } from "../utils/logger";
import {
  ImmunizationPatient,
  ImmunizationPatientReport,
  ImmunizationRecord,
  ImmunizationRecordsEnqueued,
  ImmunizationSearch,
  ScreeningEvent,
  ScreeningRecord,
  SearchStatus,
} from "../models/Interfaces";
import moment from "moment";
import _ from "lodash";
import { Dispatch } from "@reduxjs/toolkit";
import Bugsnag from "@bugsnag/browser";

// disclaimer header used for first record disclaimer card
let header = {
  dateDue: moment().format("YYYY-MM-DD"),
  dateOverdue: moment().format("YYYY-MM-DD"),
  earliestDateToGive: moment().format("YYYY-MM-DD"),
  latestDateToBeGiven: moment().format("YYYY-MM-DD"),
  patient: {} as ImmunizationPatient,
  patientId: "disclaimer",
  recordsId: 0,
  searchId: 0,
  searchUid: "",
  izProviderId: 0,
  izProviderKey: "",
  status: "disclaimer",
  vaccineType: "disclaimer",
  events: [],
};

/**
 * useGetSearches
 *
 * @remarks
 * Custom hook to fetch, parse and dispatch (to redux) searches from the API - possibly
 * handle some business logic as well
 *
 * @returns void
 *
 */

export type GetPatientRecords = {
  reports: ImmunizationPatientReport[];
  patients: ImmunizationPatient[];
};

export const useGetSearches = (refresh: boolean) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getSearches = async () => {
      const searches: ImmunizationSearchAPI | null = await getAPIClient().getIzSearches(refresh);
      if (searches && searches?.data) {
        // transform API results to typed searches
        let parsed: ImmunizationSearch[] = createSearches(searches.data);

        dispatch(setSearches(parsed));
        // set verified patients and get records
        let results: GetPatientRecords = await getPatientRecords(parsed, dispatch);
        dispatch(setReports(results?.reports!));
        dispatch(setPatients(results?.patients!));
      }
    };
    getSearches();
  }, []);

  // const getProviderConfig = async (): Promise<void> => {
  //   const response = await getAPIClient().getProviderConfigs()
  //   infoLog(`getProviderConfig:: ${JSON.stringify(response.configs)}`)
  //   if (response.configs) {
  //     dispatch(setAPIConfig(response.configs));
  //   }
  // }
};

export const fetchSearches = async (dispatch: Dispatch) => {
  try {
    const searches: ImmunizationSearchAPI | null = await getAPIClient().getIzSearches(true);
    if (searches && searches?.data) {
      const parsed: ImmunizationSearch[] = createSearches(searches.data);
      dispatch(setSearches(parsed));

      const results: GetPatientRecords = await getPatientRecords(parsed, dispatch);
      dispatch(setReports(results?.reports!));
      dispatch(setPatients(results?.patients!));
    }
    return true;
  } catch (error) {
    // Handle error here, should be silent though
    infoLog("Error fetching searches:");
    return true;
  }
};

export const getPatientRecords = async (
  searches: ImmunizationSearch[],
  dispatch: Dispatch
): Promise<GetPatientRecords> => {
  const emptyPromise = { reports: [], patients: [] };

  const verifiedSearches = searches.filter(
    (s) =>
      s.status !== SearchStatus.noMatch &&
      s.status !== SearchStatus.basicMatchNoContacts &&
      s.dateVerified
  );

  if (verifiedSearches.length === 0) {
    return Promise.resolve(emptyPromise);
  }

  const allVerifiedPatients = verifiedSearches.flatMap((s) => s.patients);

  if (allVerifiedPatients.length === 0) {
    return Promise.resolve(emptyPromise);
  }

  let newVerified: ImmunizationPatient[] = [];
  let patientReports: ImmunizationPatientReport[] = [];

  for (const search of verifiedSearches) {
    const patients: ImmunizationPatient[] = search.patients;
    // === get the patient for the search id
    const patient = _.find(allVerifiedPatients, ["searchUid", search.uid]);
    // infoLog(`patient ------------------ ${JSON.stringify(patient)} `);
    if (!patient) {
      continue;
    }
    const collectPatientDetails = async (patient: ImmunizationPatient) => {
      const response = await getAPIClient().getIzRecord(search.uid, search.izProviderKey);
      const immun = response?.attributes;
      const dateUpdated = immun?.dequeued_at ? moment(immun.dequeued_at).format("L") : null;
      let rawScreeningRecords;
      let screeningRecord: ScreeningRecord | undefined;

      if (!dateUpdated) {
        if (search.status === SearchStatus.match && search.dateVerified !== null) {
          // set new patients that have a verified search that was in the queue, and are waiting for records
          dispatch(setRecordsQueue({ estimated_wait_time: 60, uid: search.uid }));
        }
      } else {
        // get any screenings from Records, then remove from records queue
        rawScreeningRecords = await getAPIClient().getIzScreeningRecord(
          search.uid,
          search.izProviderKey
        );
        dispatch(removeFromRecordsQueue(search.uid));
      }
      if (patient.patientId) {
        patientReports = setDefaultReports(patient.firstName, patient.lastName);
        const patientRecords: ImmunizationRecord[] = [];

        patientRecords.push(header);

        if (immun?.immunizations) {
          immun.immunizations.forEach((immunization) => {
            const newRecord = createNewRecord(immunization, search.uid, immun.uid, patient);
            patientRecords.push(newRecord);
          });
        }

        if (rawScreeningRecords && rawScreeningRecords.length > 0) {
          const sortedRecords = rawScreeningRecords.sort(
            (a, b) => new Date(b.testDate || 0).getTime() - new Date(a.testDate || 0).getTime()
          );

          screeningRecord = {
            date: sortedRecords[0].testDate || null,
            patientId: patient.patientId,
            searchUid: search.uid,
            izProviderId: search.izProviderId,
            type: "lead",
            izProviderKey: search.izProviderKey,
            events: sortedRecords,
            patient: patient,
          };
        }

        newVerified.push({
          ...patient,
          records: patientRecords,
          screenings: screeningRecord ? [screeningRecord] : [],
        });
      }
    };
    /**
     * If we fail to fetch the records for a single patient, we don't want that to break getting and setting the
     * remaining patients records.
     */
    try {
      await collectPatientDetails(patient);
    } catch (error) {
      if (error instanceof DocketAPIError) {
        Bugsnag.notify(error);
      } else {
        throw error;
      }
    }
  }

  return Promise.resolve({
    reports: patientReports,
    patients: newVerified,
  });
};
