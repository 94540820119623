import React from "react";
import "./footer.css";

export function Footer() {
  return (
    <footer className="docket-footer p-20" data-testid="footer">
      <div className="container">
        <div className="columns is-vcentered is-justify-space-between">
          <div className="column">
            <p className="logo">
              <a href="/" className="logo">
                <img
                  alt="Docket"
                  className="footer-logo"
                  src="/assets/images/Docket_Logo_White.png"
                />
              </a>
            </p>
            <p>
              © 2023 Docket Health, Inc.
              <br />
              All rights reserved.
            </p>
          </div>
          <div className="column">
            <nav>
              <ul className="navbar is-flex-direction-row">
                <li className="navbar-item">
                  <a
                    className="has-text-white"
                    target="_blank"
                    href="https://docket.care/Docket_Immunizations_FAQs_Overview.pdf"
                  >
                    Immunization FAQs
                  </a>
                </li>
                <li className="navbar-item">
                  <a
                    className="has-text-white"
                    target="_blank"
                    href="https://docket.care//terms_of_use"
                  >
                    Terms of Use
                  </a>
                </li>
                <li className="navbar-item">
                  <a
                    className="has-text-white"
                    target="_blank"
                    href="https://docket.care//privacy_policy"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="navbar-item">
                  <a
                    className="has-text-white"
                    target="_blank"
                    href="https://docket.care//security"
                  >
                    Security
                  </a>
                </li>
                <li className="navbar-item">
                  <a
                    className="has-text-white"
                    target="_blank"
                    href="/"
                    data-bs-toggle="modal"
                    data-bs-target="#login-modal"
                  >
                    Home
                  </a>
                </li>
              </ul>
            </nav>
            <br />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column">
            <ul className="social-icons is-flex is-justify-content-center">
              <li className="list-group-item ml-2 mr-2">
                <a href="mailto:info@dockethealth.com">
                  <img alt="Email" width="30px" src="/assets/images/footer_email.png" />
                </a>
              </li>
              <li className="list-group-item ml-2 mr-2">
                <a href="https://www.linkedin.com/company/foxhall-wythe-llc">
                  <img alt="LinkedIn" width="30px" src="/assets/images/footer_linkedin.png" />
                </a>
              </li>
              <li className="list-group-item ml-2 mr-2">
                <a href="https://twitter.com/DocketHealthApp">
                  <img alt="Twitter" width="30px" src="/assets/images/footer_twitter.png" />
                </a>
              </li>
              <li className="list-group-item ml-2 mr-2">
                <a href="https://www.instagram.com/dockethealthapp" className="instagram">
                  <img alt="Instagram" width="30px" src="/assets/images/footer_instagram.png" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
