import React, { ChangeEvent, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../hooks";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./settings.css";
import { pwdCheck } from "../../utils/data";
import { DocketAPIError, getAPIClient, SignupRequest } from "../../apiClient";
import { errorLog, infoLog } from "../../utils/logger";
import { reset as resetLoginSlice, setAuthorized, setEmail } from "../../redux/loginSlice";
import db, { Key } from "../../database";
import UserInfo from "../onboarding/UserInfo";
import moment from "moment";
import {
  checkBoxValueMap,
  getHorizontalRadioSection,
  getLocalizedLegalSexChoices,
  getWhoAmILegacy,
} from "../../utils/formUtils";
import DatePicker from "react-datepicker";
import lodash from "lodash";
import { reset as resetImmunizationsSlice } from "../../redux/immunizationsSlice";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import { UserAccount, UserAccountWithPassword } from "../../models/Interfaces";
import { formatNumber } from "../../utils/data";
import EnterPhonePin from "../onboarding/EnterPhonePin";
import VerifyPhone from "../onboarding/VerifyPhone";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { Modal } from "../../components/modals/Modal";
import { DocketSwitch } from "../../components/form/DocketSwitch";

export function Settings() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteAccountChecked, setDeleteAccountChecked] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<string | boolean>(false);

  const [dob, setDob] = useState(moment().subtract(18, "years").toDate());
  // DOB checkbox, to map selection to values
  // ToDo: set initial state from prev search or user object
  const [checkboxValue, setCheckboxValue] = useState(getLocalizedLegalSexChoices(t));
  const checkboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(checkboxValue, index);
    infoLog(`newValue ${JSON.stringify(newValue)}`);
    setCheckboxValue(newValue);
  };
  const [selectedDisableAll, setSelectedDisableAll] = useState<boolean>(false);
  const [selectedEmailResults, setSelectedEmailResults] = useState<boolean>(false);
  const [selectedEmailVaccines, setSelectedEmailVaccines] = useState<boolean>(false);
  const [selectedAppUpdates, setSelectedAppUpdates] = useState<boolean>(false);

  const [verifyStep, setVerifyStep] = useState(true);

  const searchFormTranslations = {
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
  };

  const updateName = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  });

  const updateProfile = yup.object().shape({
    dateOfBirth: yup.string().required(),
    legalSex: yup.string().required(),
  });

  const {
    register,
    formState: { errors, isValid, isDirty, isSubmitting },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(updateName),
  });

  const {
    register: register3,
    formState: { errors: errors3, isValid: isValid3 },
    handleSubmit: handleSubmitUserInfo,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(updateProfile),
  });
  const { register: register4, handleSubmit: handleDeleteAccount } = useForm();
  const [user, setUser] = useState(null as unknown as UserAccount);

  useEffect(() => {
    if (!user) {
      db()
        .getItem(Key.UserAccount)
        .then((result) => {
          setUser(result as UserAccount);
          setSelectedDisableAll(
            (result as UserAccount)?.notification_preferences?.disable_all || false
          );
          setSelectedEmailVaccines(
            (result as UserAccount)?.notification_preferences?.email_upcoming_vaccines || false
          );
          setSelectedEmailResults(
            (result as UserAccount)?.notification_preferences?.email_new_results || false
          );
          setSelectedAppUpdates(
            (result as UserAccount)?.notification_preferences?.email_app_updates || false
          );

          const newValue = checkBoxValueMap(
            checkboxValue,
            lodash.findIndex(checkboxValue, function (item) {
              return (
                item.value === (result as UserAccount).legal_sex ||
                item.value === (result as UserAccount).legal_sex?.charAt(0).toUpperCase()
              );
            })
          );
          setCheckboxValue(newValue);
          if ((result as UserAccount).dob) {
            setDob(moment((result as UserAccount).dob).toDate());
          }
        });
    }
    return () => {};
  }, [user]);

  const getStatusModal = () => {
    return (
      <>
        {showStatusModal && (
          <Modal
            onConfirm={() => navFromModal()}
            title={t("menu.settings")}
            onCloseModal={() => setShowStatusModal(false)}
            hasClose={true}
          >
            <p>{t("settings.update_confirmation")}</p>
          </Modal>
        )}
      </>
    );
  };

  const getDeleteAccountModal = () => {
    return (
      <div className={showDeleteModal ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{t("settings.delete_account")}</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setShowDeleteModal(false)}
            ></button>
          </header>
          <section className="modal-card-body has-text-black">
            <p>{t("settings.delete_account_message")}</p>
            <br />
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={() => setShowDeleteModal(false)}>
              {t("generic.cancel")}
            </button>
            <button className="button is-success" onClick={() => onDeleteUserAccount()}>
              {t("generic.delete").toUpperCase()}
            </button>
          </footer>
        </div>
      </div>
    );
  };

  const navFromModal = () => {
    setShowStatusModal(false);
  };

  const onSubmit = async (data: any) => {
    try {
      let params = {
        first_name: data.firstName,
        last_name: data.lastName,
      };
      infoLog(`name update params ${JSON.stringify(params)}`);
      await getAPIClient().updateUser(params);
      let userResults = await getAPIClient().getUser();
      if (userResults) {
        // we still need to persist our tokens
        await db().setItem(Key.UserAccount, { ...userResults, tokens: user.tokens });
      }
      setShowStatusModal(true);
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  const getInputField = (
    yupField: "firstName" | "lastName",
    displayName: string,
    defaultVal: string = ""
  ) => {
    return (
      <div key={yupField}>
        <div className="field">
          <label className="form-input-label">{displayName}</label>
          <div className="control has-background-white">
            <input
              className="input has-text-dark"
              type="text"
              placeholder={displayName}
              defaultValue={defaultVal}
              {...register(yupField)}
            />
          </div>
        </div>
        <p className="label is-size-6">{errors[yupField] && t("system.required_error")}</p>
      </div>
    );
  };

  const formTranslations = {
    email: t("onboarding.email-address"),
    firstName: t("immunizations.search_my_first_name_label"),
    lastName: t("immunizations.search_my_last_name_label"),
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
    childFirstName: t("immunizations.search_child_first_name_label"),
    childLastName: t("immunizations.search_child_last_name_label"),
    childDateOfBirth: t("immunizations.search_child_dob_label"),
    childLegalSex: t("immunizations.search_child_legal_sex_label"),
    consentTitle: t("immunizations.search_consent_title"),
    consentMessage: t("immunizations.search_consent_message"),
    consentCheckBoxLabel: t("immunizations.i_consent"),
  };

  const ageCheckPassed = () => {
    if (dob && moment().subtract(13, "years").isBefore(moment(dob), "day")) {
      return false;
    }
    return true;
  };

  const onSubmitUserInfo = async () => {
    if (!ageCheckPassed()) {
      errorLog({ message: t("settings.dob_error_message") } as Error);
      setShowErrorMessage(`${t("settings.dob_error_message")}`);
      return;
    }
    try {
      let sex = lodash.find(checkboxValue, ["checked", true]);
      let legalSex = sex?.value ?? "U";
      const params = {
        legal_sex: legalSex,
        dob: moment(dob).toISOString(),
        language: "en",
      };
      infoLog(`onSubmitUserInfo ${JSON.stringify(params)}`);
      await getAPIClient().updateUser(params);
      let userResults = await getAPIClient().getUser();
      if (userResults) {
        await db().setItem(Key.UserAccount, {
          ...userResults,
          dob: moment(dob).format("L"),
          tokens: user.tokens,
        });
      }
      setShowStatusModal(true);
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  const getUserUpdateForm = () => {
    return (
      <div className="mt-2">
        <label className="form-input-label">{searchFormTranslations.dateOfBirth}</label>
        <DatePicker
          key={"dateOfBirth"}
          selected={dob}
          inline={false}
          {...register3("dateOfBirth")}
          customInput={
            <input className="input has-dark-text" id="dateOfBirth" placeholder=""></input>
          }
          onChange={(date) => setDob(date!)}
        />
        <br />
        <br />
        {getHorizontalRadioSection(
          checkboxValue,
          searchFormTranslations.legalSex,
          checkboxHandler,
          "checkbox has-text-dark",
          "gender",
          t
        )}
        <br />
      </div>
    );
  };

  const getDeleteAccountForm = () => {
    return (
      <div className="mt-4">
        <label className="has-text-black">
          <input
            type="checkbox"
            checked={deleteAccountChecked}
            onChange={() => setDeleteAccountChecked(!deleteAccountChecked)}
          />
          &nbsp;&nbsp; {t("settings.delete_account_confirmation")}
        </label>
      </div>
    );
  };

  const onDeleteUserAccount = async () => {
    try {
      // Initiates the deleteToken() operation and continues without waiting.
      getAPIClient().deleteToken();
      await getAPIClient().deleteUser();
      resetLoginSlice();
      resetImmunizationsSlice();
      await db()
        .clear()
        .then(() => {
          navigate("/");
        });
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  const notificationHasChanged = () => {
    return (
      selectedDisableAll !== user?.notification_preferences?.disable_all ||
      selectedEmailVaccines !== user?.notification_preferences?.email_upcoming_vaccines ||
      selectedEmailResults !== user?.notification_preferences?.email_new_results ||
      selectedAppUpdates !== user?.notification_preferences?.email_app_updates
    );
  };

  const handleSaveNotificationPreferences = async () => {
    const notification_preferences = {
      disable_all: selectedDisableAll,
      email_upcoming_vaccines: selectedDisableAll ? false : selectedEmailVaccines,
      email_app_updates: selectedDisableAll ? false : selectedAppUpdates,
      email_new_results: selectedDisableAll ? false : selectedEmailResults,
    };
    try {
      await getAPIClient().updateUser({ notification_preferences });
      let userResults = await getAPIClient().getUser();
      if (userResults) {
        await db().setItem(Key.UserAccount, { ...userResults, tokens: user.tokens });
      }
      setShowStatusModal(true);
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  return (
    <>
      {pathname === "/home/settings" && (
        <div className="section">
          {getStatusModal()}
          {getDeleteAccountModal()}
          {showErrorMessage && (
            <ErrorModal onCloseModal={() => setShowErrorMessage(false)}>
              {showErrorMessage}
            </ErrorModal>
          )}

          <p className="is-size-5 has-text-black">{t("settings.review_info")}</p>
          {user && (
            <p className="has-text-dark">
              <label className="form-input-label">{formTranslations.email}:</label> {user.email}
            </p>
          )}
          <br />
          <hr className="new1" />
          <br />
          <form key={1} onSubmit={handleSubmit(onSubmit)}>
            <label className="settings-header-label has-text-weight-bold is-size-4">
              {t("settings.update_name")}
            </label>
            <div className="columns mt-2">
              <div className="column">{getInputField("firstName", formTranslations.firstName)}</div>
              <div className="column">{getInputField("lastName", formTranslations.lastName)}</div>
            </div>
            <button
              className="button docket-button mt-2 pr-4 pl-4"
              type="submit"
              disabled={!isValid}
            >
              {t("settings.update_name").toUpperCase()}
            </button>
          </form>
          <br />
          <hr className="new1" />
          <br />
          <form key={3}>
            <label className="settings-header-label has-text-weight-bold is-size-4">
              {t("settings.update_profile")}
            </label>
            {getUserUpdateForm()}
            <button
              className="button docket-button mt-2 pr-4 pl-4"
              type="button" // Change the type to "button" to prevent default form submission
              onClick={() => onSubmitUserInfo()} // Manually invoke the form submission
            >
              {t("settings.update_profile")}
            </button>
          </form>
          <br />
          <hr className="new1" />
          <br />
          <label className="settings-header-label has-text-weight-bold is-size-4">
            {t("settings.update_contact_phone_number")}
          </label>
          {user?.verified_phone_number && (
            <p className="is-size-6 has-text-weight-bold has-text-black">
              {t("settings.current_phone_number", {
                phoneNumber: formatNumber(user?.verified_phone_number ?? ""),
              })}
            </p>
          )}
          <div className="columns mt-1 ml-1">
            {verifyStep && <VerifyPhone afterSubmit={() => setVerifyStep(false)} />}
            <br />
            {!verifyStep && <EnterPhonePin />}
          </div>
          <br />
          <hr className="new1" />
          <br />
          <label className="settings-header-label has-text-weight-bold is-size-4 mb-4">
            {t("settings.notif_prefs_header")}
          </label>
          <div>
            <label className="checkbox has-text-dark is-flex is-flex-align-items-center mt-4 mb-2">
              <DocketSwitch
                checked={selectedDisableAll}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedDisableAll(checked);
                  if (checked === true) {
                    setSelectedEmailResults(false);
                    setSelectedEmailVaccines(false);
                    setSelectedAppUpdates(false);
                  }
                }}
                id={"disable-notif"}
                name={"disable-notif"}
              />
              <span className="ml-4">{t("settings.notif_prefs_disable")}</span>
            </label>
          </div>
          <p className="is-size-7 has-text-italic has-text-black mt-4 mb-4">
            <i>{t("settings.notif_disable_message")}</i>
          </p>
          <label className="is-size-6 has-text-weight-bold has-text-black mt-2">
            {t("settings.notif_prefs_email")}
          </label>
          <div>
            <label className="checkbox has-text-dark is-flex is-flex-align-items-center mt-4 mb-4">
              <DocketSwitch
                disabled={selectedDisableAll}
                checked={selectedEmailVaccines}
                onChange={(e) => setSelectedEmailVaccines(e.target.checked)}
                id={"vaccine-notif"}
                name={"vaccine-notif"}
              />
              <span className={`ml-4 ${selectedDisableAll ? "has-text-grey" : ""}`}>
                {t("settings.notif_immunization_records_updates")}
              </span>
            </label>
          </div>
          <div>
            <label className="checkbox has-text-dark is-flex is-flex-align-items-center mt-4 mb-4">
              <DocketSwitch
                disabled={selectedDisableAll}
                checked={selectedEmailResults}
                onChange={(e) => setSelectedEmailResults(e.target.checked)}
                id={"results-notif"}
                name={"results-notif"}
              />
              <span className={`ml-4 ${selectedDisableAll ? "has-text-grey" : ""}`}>
                {t("settings.notif_immunization_reminders")}
              </span>
            </label>
          </div>
          <div>
            <label className="checkbox has-text-dark is-flex is-flex-align-items-center mt-4 mb-4">
              <DocketSwitch
                disabled={selectedDisableAll}
                checked={selectedAppUpdates}
                onChange={(e) => setSelectedAppUpdates(e.target.checked)}
                id={"updates-notif"}
                name={"updates-notif"}
              />
              <span className={`ml-4 ${selectedDisableAll ? "has-text-grey" : ""}`}>
                {t("settings.notif_immunization_versions")}
              </span>
            </label>
          </div>
          <button
            className="button docket-button mt-4 mb-4 pr-4 pl-4"
            onClick={() => handleSaveNotificationPreferences()}
            disabled={!notificationHasChanged()}
          >
            {t("settings.update_notifications")}
          </button>
          <br />
          <hr className="new1" />
          <br />
          <form key={4} onSubmit={handleDeleteAccount(() => setShowDeleteModal(true))}>
            <label className="settings-header-label has-text-weight-bold is-size-4">
              {t("settings.delete_account")}
            </label>
            <p className="is-size-6 has-text-weight-bold has-text-black mt-2">
              {t("settings.delete_account_confirmation_prompt")}
            </p>
            {getDeleteAccountForm()}
            <button
              className="button docket-button mt-4 pr-4 pl-4"
              type="submit"
              disabled={!deleteAccountChecked}
            >
              {t("settings.delete_button_label")}
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default Settings;
