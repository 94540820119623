import React, { useState } from "react";
import "./login.css";
import { DocketAPIError, getAPIClient } from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { errorLog } from "../../utils/logger";
import db, { Key } from "../../database";
import { UserAccount } from "../../models/Interfaces";
import { ErrorModal } from "../../components/modals/ErrorModal";

export const VerifyPhoneSchema = yup.object().shape({
  phone: yup.string().required().min(14).max(14),
});

export function VerifyPhone({ afterSubmit }: { afterSubmit?: () => void }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();

  const [number, setNumber] = useState("");
  const [formattedNumber, setFormattedNumber] = useState("");
  const [sendFromSettings, setSendFromSettings] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(VerifyPhoneSchema),
  });

  const searchFormTranslations = {
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
  };

  const onSendFromSettings = async (data: any) => {
    try {
      const user = await db().getItem<UserAccount>(Key.UserAccount);
      if (user && user.verified_phone_number === number) {
        setShowErrorMessage(
          t("onboarding.phone_already_verified", { phoneNumber: formattedNumber })
        );
        return;
      }

      if (number.length !== 10) {
        return;
      }

      await getAPIClient().addUserAccountPhone({ phone_number: number });

      if (afterSubmit) {
        afterSubmit();
      }
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const user = await db().getItem<UserAccount>(Key.UserAccount);
      if (user && user.verified_phone_number === number) {
        setShowErrorMessage(
          t("onboarding.phone_already_verified", { phoneNumber: formattedNumber })
        );
        return;
      }

      if (number.length !== 10) {
        return;
      }

      await getAPIClient().addUserAccountPhone({ phone_number: number });

      if (sendFromSettings) {
        if (afterSubmit) {
          afterSubmit();
        }
      } else {
        navigate("/signup/enterpin");
      }
    } catch (e: unknown) {
      errorLog(e as Error);
      if (e instanceof DocketAPIError) {
        setShowErrorMessage(e.message);
      }
    }
  };

  function onTextChange(text: string) {
    const cleaned = ("" + text).replace(/\D/g, "");
    setNumber(cleaned);
    if (cleaned.length < 11) {
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? "+1 " : "";
        const number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
        setFormattedNumber(number);
        return;
      }
      setFormattedNumber(cleaned);
    }
  }

  const Checkmark = () => (
    <span>
      <i className="fa-solid fa-circle-check has-text-success"></i>
    </span>
  );

  const getPhoneLayout = () => {
    if (pathname === "/signup/verifyphone") {
      // sign up flow
      return (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="is-fullheight is-flex is-flex-direction-column is-justify-content-space-between"
        >
          {showErrorMessage && (
            <ErrorModal onCloseModal={() => setShowErrorMessage(false)}>
              {showErrorMessage}
            </ErrorModal>
          )}
          <div className="is-flex is-flex-direction-column">
            <h1 className="has-text-dark is-size-4">{t("onboarding.signup_header")}</h1>
            <div className="separator"></div>

            <div className="input-container">
              <label className="form-input-label mb-2">
                {t("onboarding.verify-enter-phone-desc")}
              </label>
              <div className="control has-background-white position-relative">
                <input
                  className="input has-text-dark"
                  type="text"
                  placeholder="(xxx) xxx-xxxx"
                  value={formattedNumber}
                  {...register("phone")}
                  onChange={(event) => onTextChange(event.target.value)}
                />
                {formattedNumber.length === 14 && <Checkmark />}
              </div>
            </div>
            <p className="mt-4 has-text-dark is-size-7">
              {t("onboarding.verify-enter-phone-small-print-skip")}
            </p>
          </div>
          <div className="is-flex is-flex-direction-column is-align-items-center">
            <button
              className="button docket-button is-fullwidth mt-4"
              type="submit"
              disabled={formattedNumber.length === 14 ? false : true}
            >
              {t("generic.next").toLocaleUpperCase()}
            </button>
            <div className="is-fullWidth has-text-centered mt-6">
              <a
                className="mt-4 docket-link has-text-link nounderline"
                onClick={() => navigate("/home/search")}
              >
                {t("generic.skip").toLocaleUpperCase()}
              </a>
            </div>
          </div>
        </form>
      );
    } else if (pathname === "/home/settings") {
      // settings update phone
      return (
        <blockquote className="is-size-6 mt-2">
          {showErrorMessage && (
            <ErrorModal onCloseModal={() => setShowErrorMessage(false)}>
              {showErrorMessage}
            </ErrorModal>
          )}
          <form onSubmit={handleSubmit(onSendFromSettings)}>
            <div className="input-container">
              <label className="form-label-input has-text-dark form-input-label">
                {t("onboarding.verify-enter-phone-desc")}
              </label>
              <div className="control has-background-white position-relative">
                <input
                  className="input has-text-dark"
                  type="text"
                  placeholder="(xxx) xxx-xxxx"
                  value={formattedNumber}
                  {...register("phone")}
                  onChange={(event) => onTextChange(event.target.value)}
                />
                {formattedNumber.length === 14 && (
                  <span className="password-toggle home">
                    <Checkmark />
                  </span>
                )}
              </div>
            </div>
            <p className="mt-4 has-text-dark is-size-7">
              {t("onboarding.verify-enter-phone-small-print")}
            </p>

            <button
              className={`button mt-5 docket-button pl-4 pr-4`}
              type="submit"
              disabled={formattedNumber.length === 14 && !sendFromSettings ? false : true}
            >
              {t("generic.send").toUpperCase()}
            </button>
          </form>
        </blockquote>
      );
    } else {
      throw new Error("Sorry, this is not supported");
    }
  };

  return <>{getPhoneLayout()}</>;
}

export default VerifyPhone;
