import { createSlice, createAction, PayloadAction, createSelector } from "@reduxjs/toolkit";
import {
  ImmunizationPatient,
  ImmunizationPatientReport,
  ImmunizationRecordsEnqueued,
  ImmunizationSearch,
  ImmunizationSearchesEnqueued,
  ImmunizationsState,
  SearchStatus,
} from "../models/Interfaces";
import { ImmunizationProviderConfig } from "../apiClient";
import * as data from "../utils/data";
import * as lodash from "lodash";
import { infoLog } from "../utils/logger";
import i18n from "../i18n";

export const initialState: ImmunizationsState = {
  gaveConsent: false,
  patients: [] as ImmunizationPatient[],
  searches: [] as ImmunizationSearch[],
  search: null as unknown as ImmunizationSearch,
  verifiedSearchId: 0,
  pinAttempts: 0,
  error: null as unknown as string,
  reports: [] as ImmunizationPatientReport[],
  searchQueue: [] as ImmunizationSearchesEnqueued[],
  recordsQueue: [] as ImmunizationRecordsEnqueued[],
  updateRecordsQueue: [] as ImmunizationRecordsEnqueued[],
  providerConfigs: [] as ImmunizationProviderConfig[],
  njFeatureFlag: true,
  // ToDo: fix i18n - not working here i18n.t("immunizations.search_state_label")
  providerLabels: ["Make a selection:"] as string[],
};

export const immunizationsSlice = createSlice({
  name: "immunizations",
  initialState,
  reducers: {
    setSearches: (state, action: PayloadAction<ImmunizationSearch[]>) => {
      //if (state.searchQueue.length > 0) {
      action.payload.forEach((search) => {
        let searchInQueue = state.searchQueue.filter((s) => s.uid === search.uid);
        if (searchInQueue.length > 0) {
          let searchToEnqueue = searchInQueue[0];
          if (search.status !== SearchStatus.inQueue) {
            let newQueue = lodash.remove(state.searchQueue, function (searchInQueue) {
              return search.uid === searchInQueue.uid;
            });
            infoLog(
              `removing from queue ------------------ ${JSON.stringify(
                search
              )} newQueue ${JSON.stringify(newQueue)} `
            );
            setSearchQueue(newQueue);
            if (search.status === SearchStatus.match || search.dateVerified !== null) {
              // set new patients that have a verified search that was in the queue, and are waiting for records
              const newRecordsQueueItem = searchInQueue.find((s) => s.uid === search.uid);
              if (newRecordsQueueItem) {
                setRecordsQueue(newRecordsQueueItem);
              }
            }
          }
        } else if (search.status === SearchStatus.inQueue && searchInQueue.length === 0) {
          // restore the queue from login or refresh
          setSearchQueue([{ estimated_wait_time: 60, uid: search.uid }]);
        }
      });
      //}
      state.searches = action.payload;
    },
    deleteSearch: (state, action: PayloadAction<ImmunizationSearch>) => {
      if (action.payload.patients[0]) {
        state.patients = state.patients.filter(
          (patient) =>
            !(
              patient.patientId === action.payload.patients[0].patientId &&
              action.payload.uid === patient.searchUid
            )
        );
      }
      state.searches = state.searches.filter((search) => search.uid !== action.payload.uid);
    },
    setAPIConfig: (state, action: PayloadAction<ImmunizationProviderConfig[]>) => {
      const label = state.providerLabels[0];
      const sortedAndFiltered = data
        .sortApiConfigAscending(action.payload)
        .filter((config) => config && config.enabled && config.features?.pwa_enabled);
      //infoLog(`sortedAndFiltered:: ${JSON.stringify('')} ${sortedAndFiltered.length}`)
      if (sortedAndFiltered && sortedAndFiltered.length > 0) {
        const labels = sortedAndFiltered.reduce<string[]>(function (start, config, index) {
          if (index === 0) {
            start.push(label);
          }
          start.push(
            i18n.t("immunizations.search_state_label", {
              context: config.iz_provider.destination_id,
            })
          );
          return start;
        }, []);
        const njFeatures = sortedAndFiltered.find(
          (config) => config.iz_provider.destination_id === "nj"
        );

        const toggle = (njFeatures && njFeatures.features?.covid_toggle) ?? false;
        //infoLog(`sortedAndFiltered:: ${JSON.stringify(njFeatures)} ${toggle}`)
        state.providerConfigs = sortedAndFiltered;
        state.providerLabels = labels;
        state.njFeatureFlag = toggle;
      }
      //state.providerConfigs = action.payload;
    },
    setSearchQueue: (state, action: PayloadAction<ImmunizationSearchesEnqueued[]>) => {
      state.searchQueue = [...state.searchQueue, ...action.payload];
    },
    setRecordsQueue: (state, action: PayloadAction<ImmunizationRecordsEnqueued>) => {
      state.recordsQueue = [...state.recordsQueue, action.payload];
    },
    setUpdateRecordsQueue: (state, action: PayloadAction<ImmunizationRecordsEnqueued>) => {
      state.updateRecordsQueue = [...state.updateRecordsQueue, action.payload];
    },
    removeFromRecordsQueue: (state, action: PayloadAction<string>) => {
      state.recordsQueue = state.recordsQueue.filter((record) => record.uid !== action.payload);
    },
    setPatients: (state, action: PayloadAction<ImmunizationPatient[]>) => {
      state.patients = action.payload;
    },
    setReports: (state, action: PayloadAction<ImmunizationPatientReport[]>) => {
      state.reports = action.payload;
    },
    setSearch: (state, action: PayloadAction<ImmunizationSearch>) => {
      state.search = action?.payload;
    },
    setGaveConsent: (state, action: PayloadAction<boolean>) => {
      state.gaveConsent = action.payload;
    },
    setProviderConfigs: (state, action: PayloadAction<ImmunizationProviderConfig[]>) => {
      state.providerConfigs = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    reset: () => initialState,
  },
});

//export const selectSearches = (state: RootState) => state.immunizations.searches;
//export const selectSearchQueue = (state: RootState) => state.immunizations.searchQueue;
// export const selectAllSearches = createSelector(
//   [selectSearches],
//   (searches) => {
//     // do something with a, b, and c, and return a result
//     return searches;
//   }
// );

export const {
  setSearches,
  setPatients,
  setReports,
  setSearch,
  deleteSearch,
  setSearchQueue,
  removeFromRecordsQueue,
  setRecordsQueue,
  setUpdateRecordsQueue,
  setGaveConsent,
  setProviderConfigs,
  setError,
  reset,
  setAPIConfig,
} = immunizationsSlice.actions;

export default immunizationsSlice.reducer;
